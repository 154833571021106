import { ActionType } from "../action-types";
import { Action } from "../actions";
import {
  setTokens,
  setCurrencies,
  setCurrentAssets,
  setFilteredAssets,
  setCoins,
  setUnmeasuredCoins,
} from "../action-creators";
import { getCoinList, getUnmeasuredCoinList } from "../../services/currency-backend-service";
import { Dispatch } from "redux";
import { Coin, Currency, ICoinLogo, Token } from "../../typings/CurrencyTypes";

import {filterCoinsByAssetType} from "../../services/currency-helpers"
import { getCoinLogos } from "../../services/coin-logos";

interface ICurrencyState {
  currencies: Currency[],
  tokens: Token[],
  allCoins: Coin[]
  allCoinsLogos: ICoinLogo[],
  unmeasuredCoins: Coin[]
}
const initialState:ICurrencyState = {
  currencies: [],
  tokens: [],
  allCoins: [],
  allCoinsLogos: [],
  unmeasuredCoins: []
};

export const currencyReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_CURRENCIES: {
      return {
        ...state,
        currencies: action.payload,
      };    }
    case ActionType.SET_TOKENS: {
      return {
        ...state,
        tokens: action.payload,
      };
    }
    case ActionType.SET_UNMEASURED_COIN: {
      return {
        ...state,
        unmeasuredCoins: action.payload,
      };
    }
    case ActionType.SET_COINS: {
      return {
        ...state,
        allCoins: action.payload,
        allCoinsLogos: [...getCoinLogos(action.payload)]
      };
    }
    default:
      return state;
  }
};


export const loadCurrenciesAndTokens =
  () =>
  async (dispatch: (arg0: (dispatch: Dispatch<Action>) => void) => void) => {
    const currencyList = await getCoinList("currencies");
    const tokenList = await getCoinList("tokens")
    const unmeasuredCoinList = await getUnmeasuredCoinList();
    const coinList = [...currencyList, ...tokenList, ...unmeasuredCoinList]
    dispatch(setTokens(tokenList));
    dispatch(setCurrencies(currencyList));
    dispatch(setCoins(coinList));
    dispatch(setUnmeasuredCoins(unmeasuredCoinList));
    dispatch(
      setCurrentAssets(
        filterCoinsByAssetType("pow", currencyList)
      )
    );
    dispatch(
      setFilteredAssets(
        filterCoinsByAssetType("pow", currencyList)
      )
    );
  };


// const loadCurrencies =
//   () =>
//   async (dispatch: (arg0: (dispatch: Dispatch<Action>) => void) => void) => {
//     const currencyList = await getCurrencyList();
//     dispatch(setCurrencies(currencyList));
//     dispatch(
//       setCurrentAssets(
//         filterCurrenciesByAssetType("pow", currencyList)
//       )
//     );
//     dispatch(
//       setFilteredAssets(
//         filterCurrenciesByAssetType("pow", currencyList)
//       )
//     );
//   };
