// copy this file as template for new components
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currenciesInfo } from "../../pages/DataMethods/dataMethodConstants";
import { RootState } from "../../state";
import { Coin, Currency, TAssetType } from "../../typings/CurrencyTypes";
// import { DataMethodsTable } from "../DataMethodsTable";
import { LoadingSpinner } from "../LoadingSpinner";
import { getOriginalCoinAssetType, isCoinUnmeasured } from "../../services/currency-helpers";
import { CointypeLabel } from "../CointypeLabel";


export interface Props {
  // selectedTicker: string | undefined;
  // onCurrencySelect: (newTicker: string) => void;
}

export const DataMethodsSelector: React.FC<Props> = (props) => {

  const coins = useSelector((state: RootState) => state.coins.allCoins);
  const unmeasuredCoins = useSelector((state: RootState) => state.coins.unmeasuredCoins);
  const coinLogos = useSelector((state: RootState) => state.coins.allCoinsLogos)
  const [coinsSorted, setCoinsSorted] = useState<Coin[]>([]);
  const [coinsLoading, setCoinsLoading] = useState(true);

  const sortCoinsByType = (coins: Coin[]) => {
    return coins.sort((a, b) => {
      const typeA = getOriginalCoinAssetType(a)!;
      const typeB = getOriginalCoinAssetType(b)!;
      const order = { pow: 1, pos: 2, token: 3, other: 4 };

      // Sort by coin type based on the defined order
      if (order[typeA] < order[typeB]) return -1;
      if (order[typeA] > order[typeB]) return 1;

      // If coin type is the same, maintain the original order
      return coins.indexOf(a) - coins.indexOf(b);
    });
  };

  useEffect(() => {
    if (coinsSorted && coinsSorted.length > 0) {
      setCoinsLoading(false)
    }
  }, [coinsSorted]);

  useEffect(() => {
    if (coins && coins.length > 0) {
      setCoinsSorted(sortCoinsByType(coins))
    }
  }, [coins]);


  const getCoinImagePath = (ticker: string) => {
    return coinLogos.find((element) => element.ticker === ticker)?.logo
  }

  // const getCoinNameByTicker = () => {
  //   return coinsSorted.find(currency => currency.ticker === props.selectedTicker)!.name
  // }

  // const getCoinInfo = () => {
  //   if (props.selectedTicker) {
  //     return currenciesInfo[props.selectedTicker]
  //   }
  // }


  // const isCardSelected = (ticker: string) => {
  //   return ticker === props.selectedTicker;
  // }

  const getCoinDocsUrl = (coin: Currency) => {
    const ccriDocsCoinBaseUrl = "https://docs.api.carbon-ratings.com/v2/#/currencies"
    const coinName = coin.ticker === "bnb" ? "binance coin" : coin.name.toLocaleLowerCase()
    const coinNameParameter = `currency=${coinName}`
    const coinTypeParameter = `type=${getOriginalCoinAssetType(coin)}`
    const url = `${ccriDocsCoinBaseUrl}?${coinNameParameter}&${coinTypeParameter}`
    return url
  }

  const handleCoinClassClicked = (newCurrency: Currency) => {
    // props.onCurrencySelect(newCurrency.ticker)
    window.open(getCoinDocsUrl(newCurrency), '_blank');
  }

  const unselectedMeasuredClasses = "cursor-pointer hover:bg-primaryblue hover:text-white"
  const unselectedUnmeasuredClasses = "cursor-not-allowed pointer-events-none bg-gray-100"
  const getUnselectedClasses = (coin: Coin) => {
    return `bg-white text-primaryblue ${isCoinUnmeasured(coin, coins, unmeasuredCoins) ? unselectedUnmeasuredClasses : unselectedMeasuredClasses}`
  }
  const selectedClasses = "bg-primaryblue text-white"

  const createCoinCard = (coin: Coin) => {
    return (
      <div
        // className={`min-w-64 h-16 rounded-lg shadow flex grow-0 shrink-0 basis-64 items-center justify-between p-6 cursor-pointer transition duration-500 ease-in-out ${isCardSelected(currency.ticker) ? selectedClasses : unselectedClasses}`}
        className={`min-w-72 h-16 rounded-lg shadow flex grow-0 shrink-0 basis-72 items-center justify-between p-6 transition duration-500 ease-in-out ${getUnselectedClasses(coin)}`}
        key={coin.ticker}
        onClick={() => handleCoinClassClicked(coin)}
      >
        <div className="flex items-center space-x-3">
          <h3 className="truncate text-sm font-medium">{coin.name}</h3>
          <CointypeLabel
            ticker={coin.ticker}
          />
        </div>
        <img className="h-10 w-10 flex-shrink-0 bg-white rounded-full" src={getCoinImagePath(coin.ticker)} alt={coin.name} />
      </div>)
  }

  const createLoadingSpinner = () => {
    return (<div className="h-7 pt-4">
      <LoadingSpinner loading={true}></LoadingSpinner>
    </div>)
  }

  const createDataMethodsSelectorTabsArea = () => {
    return (<div className="mx-4">
      <div className="flex flex-row flex-wrap justify-center gap-4">
        {coinsSorted.map((coin) => (
          createCoinCard(coin)
        ))}
      </div>
      {/* <div className="mx-4 sm:mx-16 my-8">
        <DataMethodsTable
          currencyName={getCurrencyNameByTicker()}
          currencyLogo={getCurrencyImagePath(props.selectedTicker)!}
          currencyInfo={getCurrencyInfo()} />
      </div> */}
    </div>)
  }

  return (
    <div className="mt-8">
      {coinsLoading ?
        createLoadingSpinner()
        :
        createDataMethodsSelectorTabsArea()}
    </div>
  )
};
