import React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { Heading } from "../../components/Heading";

export interface HeaderSectionProps {
  titles: string[];
  subtitle?: string;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({
  titles,
  subtitle,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="bg-hero-pattern-cut bg-no-repeat bg-cover flex lg:h-48 h-32 justify-center items-center"
      style={{
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      }}
    >
      <div className="text-center px-4">
        <div className="mb-2.5">
          {titles.map((title) => (
            <Heading key={title} size="h1" text={t(title)}></Heading>
          ))}
        </div>
        {subtitle ? (
          <Heading size="h4" text={subtitle}></Heading>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
