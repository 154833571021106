import React, { useState } from "react";

export interface CheckBoxProps {
  initialChecked?: boolean;
  label: string;
  ticker: string;
  light?: boolean;
  onCheck: (ticker: string, label: string) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  initialChecked,
  label,
  ticker,
  light,
  onCheck,
}) => {
  const [checked, setChecked] = useState(initialChecked || false);

  const handleCheck = () => {
    setChecked(!checked);
    onCheck(ticker, label);
  };

  return (
    <div
      className="flex basis-36 shrink-0 justify-start flex-row pl-4 cursor-pointer"
      onClick={handleCheck}
    >
      <div
        className={`${checked
            ? light
              ? "bg-checked-white"
              : "bg-checked"
            : light
              ? "bg-unchecked-white"
              : "bg-unchecked"
          } w-6 h-6 bg-no-repeat mr-1 cursor-pointer`}
      ></div>
      {label ? (
        <p
          className={`${light ? "text-white" : "text-primaryblue"
            } font-roboto text-sm font-light`}
        >
          {label}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
