import { TRankingType } from "./sustainabilityRankingConstants";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import { isMinerRanking } from "./sustainabilityRankingHelpers";
import CcriCertifiedIcon from "../../assets/CCRI-logo-only.png";



export interface ISustainabilityRankingContactProps {
    rankingType: TRankingType;
}

export const SustainabilityRankingContact: React.FC<ISustainabilityRankingContactProps> = (props) => {

    const { t } = useTranslation();

    const getContactText = () => {
        return isMinerRanking(props.rankingType) ? t("sustainability-ranking.text-contact-miner-1") : t("sustainability-ranking.text-contact-investor-1")
    }

    return (
        <div className="sm:max-w-md lg:max-w-2xl self-center px-5 sm:px-6 lg:px-8 mt-4 md:mt-8 mx-8 lg:mx-8 mb-0 pb-2 rounded-xl shadow-[rgba(14,_30,_37,_0.12)_0px_2px_4px_0px,_rgba(14,_30,_37,_0.32)_0px_2px_16px_0px] bg-[#f1f9ffb3]">
            <div className="my-4 flex gap-4 lg:gap-10 justify-center items-center md:justify-around flex-col lg:flex-row">
                <div className="basis-2/12 shrink-0 h-20 flex justify-center">
                    <a href="https://carbon-ratings.com">
                        <img
                            className="h-20 lg:h-full object-scale-down"
                            src={CcriCertifiedIcon}
                            alt="CCRI Certificate Label">
                        </img>
                    </a>
                </div>
                <div className="basis-auto shrink flex flex-col items-center sm:gap-4 justify-center">
                    <Paragraph
                        textAlign="center"
                        text={getContactText()}
                    ></Paragraph>
                    <div className="flex justify-center lg:justify-end">
                        <Button
                            text={t("sustainability-ranking.button-contact-1")}
                            onClick={() =>
                                window.open("mailto:hi@carbon-ratings.com")
                            }
                            type="cta"
                        ></Button>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default SustainabilityRankingContact