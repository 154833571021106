import { TAssetType, TAssetTypeGraph, TGraphdataType } from "../../typings/CurrencyTypes";

export interface fixedTimeFrame {
  months: number;
  label: string;
}

// export const assetTypes = ["Proof of Work", "Proof of Stake", "Tokens"];
// export const graphTypes = ["Electricity Consumption", "CO\u2082 Emissions"];
export type TAssetTypesMapping = {[key in TAssetType]: string}
export type TAssetTypesGraphMapping = {[key in TAssetTypeGraph]: string}
export type TGraphdataTypesMapping = {[key in TGraphdataType]: string}

export const assetTypesMapping: TAssetTypesMapping = {
  "pow": "Proof of Work",
  "pos":"Proof of Stake / Other",
  "token": "Tokens",
  "other": "Other"
}

export const assetTypesGraphMapping: TAssetTypesGraphMapping = {
  "pow": "Proof of Work",
  "pos":"Proof of Stake",
  "token": "Tokens",
}

export const graphdataTypesMapping: {[key in TGraphdataType]: string} = {
  consumption: "Electricity Consumption",
  emission: "CO\u2082 Emissions"
}


export let today = new Date();
today.setHours(0, 0, 0, 0);
export let oneYearAgo = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
);

export const timeFrames: fixedTimeFrame[] = [
  { months: 1, label: "1m" },
  { months: 3, label: "3m" },
  { months: 6, label: "6m" },
  { months: 12, label: "1y" },
  { months: 24, label: "2y" },
];

// export const chartColors: Map<string, string> = new Map([
//   ["btc", "#0877BC"],
//   ["eth", "#BBC1C4"],
//   ["ltc", "#63BAEF"],
//   ["bch", "#4F93BD"],
//   ["bsv", "#20677C"],
//   ["dash", "#4A8BB3"],
//   ["doge", "#8DA7B7"],
//   ["zec", "#5BAADB"],
//   ["ada", "#0877BC"],
//   ["avax", "#4A72B1"],
//   ["dot", "#BBC1C4"],
//   ["sol", "#34627E"],
//   ["xtz", "#687C89"],
//   ["algo", "#146EA3"],
//   ["ksm", "#597586"],
//   ["atom", "#0877BC"],
//   ["kava", "#BBC1C4"],
//   ["mina", "#63BAEF"],
//   ["xrp", "#4A72B1"],
// ]);

export const defaultColor = "#0877BC"

//blue tones currently unused!
export const blueColors = ["#0877BC", "#BBC1C4", "#63BAEF", "#4F93BD", "#20677C", "#4A8BB3", "#8DA7B7", "#5BAADB", "#0877BC", "#4A72B1", "#BBC1C4", "#34627E", "#687C89", "#146EA3", "#597586", "#0877BC", "#BBC1C4", "#63BAEF", "#4A72B1"];

export const chartTableTextLinks = [
  "https://docs.api.carbon-ratings.com/"
  // "https://www.sciencedirect.com/science/article/pii/S2542435119302557",
  // "https://www.cell.com/joule/fulltext/S2542-4351(20)30331-7",
  // "https://carbon-ratings.com/dl/pos-report-2022",
  // "https://carbon-ratings.com/dl/accounting-framework-2022",
];

export const chartTableTextFragments = [
  "general-dashboard.chart-table-text1",
  "general-dashboard.chart-table-text2",
  // "general-dashboard.chart-text1",
  // "general-dashboard.chart-text2",
  // "general-dashboard.chart-text3",
  // "general-dashboard.chart-text4",
  // "general-dashboard.chart-text5",
];

export const chartTableTextLinkTexts = [
  "general-dashboard.chart-table-text-link1"
  // "general-dashboard.chart-text-link1",
  // "general-dashboard.chart-text-link2",
  // "general-dashboard.chart-text-link3",
  // "general-dashboard.chart-text-link4",
];
