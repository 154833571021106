import {
  CoinConsumptionData,
  CoinEmissionData,
} from "../../typings/CurrencyTypes";

export const getDatesBetween = (start: Date, end: Date) => {
  let arr = [];
  for (
    let dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr.map((entry) => entry.toISOString().slice(0, 10));
  //return arr.map((entry) => new Date(entry.toISOString().slice(0, 10)));
  //return arr;
};

export const fitEmissionGraphDataToDates = (
  all_data: CoinEmissionData[] | undefined,
  startDate: string,
  endDate: string
): CoinEmissionData[] | undefined => {
  if (all_data) {
    let filteredCurrencies = [];

    const start = new Date(startDate);
    const end = new Date(endDate);
    const timespan = getDatesBetween(start, end);

    for (let element of all_data) {
      let dailyEmissions = [];
      let yearlyEmissions = [];

      for (let date of timespan) {
        if (element.dates.includes(date)) {
          dailyEmissions.push(
            element.dailyEmissions[element.dates.indexOf(date)]
          );
          yearlyEmissions.push(
            element.yearlyEmissions[element.dates.indexOf(date)]
          );
        } else {
          yearlyEmissions.push(null);
          dailyEmissions.push(null);
        }
      }
      filteredCurrencies.push({
        ticker: element.ticker,
        dates: timespan,
        dailyEmissions: dailyEmissions,
        yearlyEmissions: yearlyEmissions,
      });
    }
    return filteredCurrencies;
  } else {
    return undefined;
  }
};

export const fitConsumptionGraphDataToDates = (
  all_data: CoinConsumptionData[] | undefined,
  startDate: string,
  endDate: string
): CoinConsumptionData[] | undefined => {
  if (all_data) {
    let filteredCurrencies = [];

    const start = new Date(startDate);
    const end = new Date(endDate);
    const timespan = getDatesBetween(start, end);

    for (let element of all_data) {
      let yearlyConsumption = [];
      let dailyConsumption = [];

      for (let date of timespan) {
        if (element.dates.includes(date)) {
          yearlyConsumption.push(element.yearlyConsumption[element.dates.indexOf(date)]);
          dailyConsumption.push(element.dailyConsumption[element.dates.indexOf(date)]);
        } else {
          yearlyConsumption.push(null);
          dailyConsumption.push(null);
        }
      }
      filteredCurrencies.push({
        ticker: element.ticker,
        dates: timespan,
        yearlyConsumption: yearlyConsumption,
        dailyConsumption: dailyConsumption,
      });
    }
    return filteredCurrencies;
  } else {
    return undefined;
  }
};

export const performAutoScroll = (windowWidth: any, scrollRef: any) => {
  if (windowWidth > 768 && scrollRef && scrollRef.current) {
    setTimeout(() => {
      scrollRef!.current!.scrollIntoView({ block: 'start', inline: "start", behavior: 'smooth' });
    }, 2000);
  }
}