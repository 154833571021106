import React from "react";

export interface ButtonProps {
  text: string;
  type?: "standard" | "cta";
  dark?: boolean;
  active?: boolean;
  classes?: string;
  onClick: () => void;
}

const commonClasses: string = "font-roboto rounded-full";
const commonNonCta: string =
  "text-base md:text-lg text-secondaryblue font-regular lg:px-8 px-4 py-2";
const standardClasses: string =
  "text-secondaryblue bg-white hover:bg-lightgrey border border-lightgrey";
const standardDarkClasses =
  "text-secondaryblue bg-primaryblue border border-secondaryblue hover:bg-white";
const activeClasses: string = "bg-lightgrey";
const activeDarkClasses: string = "bg-white";

const ctaClasses: string =
  "text-white font-light md:text-2xl text-xl bg-secondaryblue px-12 py-3.5";

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      className={`${commonClasses} ${props.classes ? props.classes : ""} ${
        props.type && props.type === "cta" ? ctaClasses : commonNonCta
      } ${
        props.type !== "cta"
          ? props.active
            ? props.dark
              ? activeDarkClasses
              : activeClasses
            : props.dark
            ? standardDarkClasses
            : standardClasses
          : ""
      }`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};
