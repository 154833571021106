import React, { useState, useEffect, useRef } from "react";
import { CheckBox } from "../CheckBox";

export interface ISelectedItem {
  ticker: string;
  label: string;
}

export interface CheckBoxGroupProps {
  elements: {
    ticker: string;
    label: string;
    initialChecked: boolean;
  }[];
  currentAssetType: string;
  cols?: number;
  onChange: (options: ISelectedItem[]) => void;
  light?: boolean;
}

export const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ({
  elements,
  currentAssetType,
  onChange,
  light,
}) => {

  const [selectedItems, setSelectedItems] = useState<ISelectedItem[]>([]);

  const calledOnce = useRef(false);

  useEffect(() => {
    calledOnce.current = false;
  }, [currentAssetType])

  useEffect(() => {
    if (
      calledOnce.current && (selectedItems.length === 0 || (elements.some((element) => element.ticker === selectedItems[0].ticker)))
    ) {
      return;
    } else {
      setSelectedItems(
        elements.filter((element) => element.initialChecked).map((element) => { return { label: element.label, ticker: element.ticker } })
      );

      if (elements.length > 1) {
        calledOnce.current = true;
      }
    }
  }, [elements, selectedItems]);


  const handleSelect = (ticker: string, label: string) => {
    const newSelectedItems = selectedItems.some(element => element.ticker === ticker)
      ? selectedItems.filter((element) => element.ticker !== ticker)
      : [...selectedItems, { ticker, label }];
    onChange(newSelectedItems);
    setSelectedItems(newSelectedItems);

  };

  return (
    <div className={`flex flex-wrap justify-center gap-y-1.5`}>
      {elements.map((entry) => (
        <CheckBox
          key={entry.ticker}
          ticker={entry.ticker}
          label={entry.label}
          initialChecked={entry.initialChecked === true}
          onCheck={handleSelect}
          light={light || false}
        />
      ))}
    </div>
  );
};

