import React, { useEffect } from "react";
import "./i18n";
import { BrowserRouter, Routes, Route, useParams, Navigate } from "react-router-dom";
import { loadCurrenciesAndTokens } from "./state/reducers/currencyReducer";
import "./App.css";
import { useTranslation } from "react-i18next";
import { DataMethods } from "./pages/DataMethods";
import { SustainabilityRanking } from "./pages/SustainabilityRanking";
import About from "./pages/About/About";
import { Navbar } from "./components/Navbar";
import { NavlinkProps } from "./components/Navlink";
import { Footer } from "./components/Footer";
import { useDispatch } from "react-redux";
import GeneralDashboard from "./pages/GeneralDashboard/GeneralDashboard";
import { EthereumMerge } from "./pages/EthereumMerge";
import { Faq } from "./pages/Faq";
import { AnyAction } from "redux";
import { RedirectComponent } from "./components/RedirectComponent";
//import { RootState } from "./state";

export interface AppProps { }
const App: React.FC<AppProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCurrenciesAndTokens() as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navLinks: Array<NavlinkProps> = [
    { text: t("navigation.home"), to: "/" },
    { text: t("navigation.ethereum-merge"), to: "/ethereum-merge" },
    { text: t("navigation.sustainability-ranking"), to: "/bitcoin-sustainability-ranking" },
    { text: t("navigation.data-methods"), to: "/data-methods" },
    { text: t("navigation.faq"), to: "/faq" },
    { text: t("navigation.about"), to: "/about" },
    { text: t("navigation.homepage"), to: "http://carbon-ratings.com/" },

  ];

  const footerLinks: Array<NavlinkProps> = [
    {
      text: t("footer.imprint"),
      to: "https://carbon-ratings.com/imprint",
      type: "footer",
    },
  ];

  return (
    <BrowserRouter>
      <div className="min-h-screen">
        <div className="sticky top-0 z-50">
          <Navbar links={navLinks}></Navbar>
        </div>
        <div className="relative">
          <Routes>
            <Route path="/" element={<GeneralDashboard />}></Route>
            <Route path="/ethereum-merge" element={<EthereumMerge />}></Route>
            <Route path="/data-methods" element={<DataMethods />}></Route>
            <Route path="/bitcoin-sustainability-ranking" element={<SustainabilityRanking />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="*" element={<RedirectComponent />}></Route>
          </Routes>
          <Footer links={footerLinks}></Footer>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
