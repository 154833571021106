import React from "react";
import { Paragraph } from "../Paragraph";
import BarLoader from "react-spinners/BarLoader";

export interface LoadingSpinnerProps {
  loading: boolean;
  text?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  loading,
  text,
}) => {
  const spinner = (
    <div className="text-center">
      {text ? (
        <div className="mb-5">
          <Paragraph text={text}></Paragraph>{" "}
        </div>
      ) : (
        <></>
      )}
      <BarLoader
        color={"#283044"}
        css={"display: block; margin-left: auto; margin-right: auto;"}
        loading={loading}
        width={150}
        height={6}
      />
    </div>
  );
  return loading ? spinner : <></>;
};
