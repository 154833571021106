import React from "react";
import { Disclosure } from "@headlessui/react";

export interface NavlinkProps {
  text: string;
  to: string;
  type?: "menu" | "mobile-menu" | "footer";
  active?: boolean;
}

const footerLinkClasses: string =
  "py-1 text-white md:text-lg text-base font-roboto font-regular mr-4 hover:underline md:mr-6 underline-offset-8";

const mobileMenuClasses =
  "font-roboto font-lighttext-lg text-white hover:underline underline-offset-8";
const mobileMenuActiveClasses = " underline underline-offset-8 text-white";
const menuClasses =
  "border-transparent text-white font-roboto text-xl font-light hover:underline underline-offset-8 shrink-0 xl:text-left xl:ml-0 xl:mr-0 ml-4 mr-4 text-center";

export const Navlink: React.FC<NavlinkProps> = (props) => {
  const menuLink = (
    <a
      href={props.to}
      className={`${
        props.active ? "underline underline-offset-8" : " "
      } ${menuClasses} inline-flex items-center px-1 pt-1 border-b-2`}
    >
      {props.text}
    </a>
  );

  const mobileMenuLink = (
    <Disclosure.Button
      as="a"
      href={props.to}
      className={`${
        props.active ? mobileMenuActiveClasses : mobileMenuClasses
      } block pl-3 pr-4 text-lg py-5`}
    >
      {props.text}
    </Disclosure.Button>
  );

  const footerLink = (
    <a
      className={`${footerLinkClasses}`}
      href={props.to}
      target="_blank"
      rel="noreferrer"
    >
      {props.text}
    </a>
  );

  let navlink = null;

  if (props.type === "menu") {
    navlink = menuLink;
  } else if (props.type === "mobile-menu") {
    navlink = mobileMenuLink;
  } else {
    navlink = footerLink;
  }

  return <>{navlink}</>;
};
