import { useSelector } from "react-redux";
import { findCurrencyByTicker, getAdjustedCoinAssetType, getOriginalCoinAssetType } from "../../services/currency-helpers"
import { Coin, TAssetType } from "../../typings/CurrencyTypes"
import { RootState } from "../../state";

interface ICointypeLabelProps {
  ticker: string;
  equalSize?: boolean;
  footnoteNumber?: number;
}


export const CointypeLabel: React.FC<ICointypeLabelProps> = (props) => {

  const coins = useSelector((state: RootState) => state.coins.allCoins);

  const getReadableCoinType = (type: TAssetType) => {
    if (type === "pow")
      return "PoW"
    else if (type === "pos")
      return "PoS"
    else if (type === "token")
      return "Token"
    else
      return "Other"
  }

  const getCoinTypeLabelColor = (type: TAssetType) => {
    if (type === "pow")
      return "bg-green-100"
    else if (type === "pos")
      return "bg-blue-100"
    else if (type === "token")
      return "bg-red-100"
    else
      return "bg-yellow-100"
  }

  const coin = findCurrencyByTicker(coins, props.ticker)

  const renderCoinTypeLabel = () => {
    const adjustedCoinType = getAdjustedCoinAssetType(coin)
    const readableCoinType = getReadableCoinType(adjustedCoinType!)
    const colorCoinType = getCoinTypeLabelColor(adjustedCoinType!)
    return (
      <span className={`inline-block flex-shrink-0 text-center rounded-full px-2 py-0.5 text-xs font-medium !text-black ${colorCoinType} ${props.equalSize ? "w-[50px]" : ""}`}>
        {readableCoinType}{props.footnoteNumber && <sup>{props.footnoteNumber}</sup>}
      </span>
    )
  }

  return (
    renderCoinTypeLabel()
  )
}