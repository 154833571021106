import React from "react";
import { CCRI_CERTIFIED_INVESTOR_INDEX, CCRI_CERTIFIED_MINER_INDEX, GRAPH_Y_AXIS_INDEX, ISustainabilityData, tableValueDecimalsPerColumnNumber, TRankingType } from "./sustainabilityRankingConstants";
import { isMinerRanking } from "./sustainabilityRankingHelpers";
import CcriCertifiedIcon from "../../assets/CCRI-logo-only.png";



export interface ISustainabilityRankingTableProps {
    data: ISustainabilityData[];
    rankingType: TRankingType;
}

export const SustainabilityRankingTable: React.FC<ISustainabilityRankingTableProps> = (props) => {

    const formatTableNumber = (inputNumber: number | string, columnNumer: number) => {
        const numberDigits = (tableValueDecimalsPerColumnNumber[props.rankingType] as { [key: number]: number })[columnNumer] ?? 1 //defaults to 1 if no specific value in object using Nullish coalescing operator, since || does not work as 0 is treated as falsy and would be changed to 1
        const formattedValue = (+inputNumber as number).toLocaleString("en-us", { maximumFractionDigits: numberDigits })
        return formattedValue
    }

    const getCustomColumnStyle = (index: number) => {
        if (index === 0) {
            if (props.rankingType === "miner-ranking") {
                return "w-[20%] text-left"
            }
            if (props.rankingType === "investor-ranking") {
                return "w-[40%] text-left"
            }
        }
        // return text right if non of the above holds
        return "text-center"
    }

    const drawCcriCertifiedIcon = (rowElem: ISustainabilityData, rowIndex: number, isDesktopRepresentation: boolean) => {
        if (Object.values(rowElem)[isMinerRanking(props.rankingType) ? CCRI_CERTIFIED_MINER_INDEX : CCRI_CERTIFIED_INVESTOR_INDEX] === "1") {
            return (
                <img
                    key={`miner-holder-row-certified-${rowIndex}`}
                    className={`inline-block ${isDesktopRepresentation ? "h-6 w-6" : "h-8 w-8"}`}
                    src={CcriCertifiedIcon}
                    alt={"1"}
                />
            )
        } else {
            return <></>
        }
    }

    const createSustainabilityRankingTable = () => {
        return (<>
            <div className="block xl:hidden">
                {createTableMobile()}
            </div>
            <div className="hidden xl:block">
                {createSustainabilityRankingTableDesktop()}
            </div>

        </>
        )
    }


    const createSustainabilityRankingTableDesktop = () => {
        return (
            <div className="-my-2 -mx-4 overflow-x-hidden sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="table-fixed w-full min-w-full divide-y divide-gray-300">
                        {createTableDesktopHeader()}
                        {createTableDesktopBody()}
                    </table>
                </div>
            </div>)

    }

    const createTableDesktopHeader = () => {
        const headerKeys = Object.keys(props.data[0])
        const tableHeaders = headerKeys.map((elem, index) => {
            const headerCell =
                <th
                    scope="col"
                    className={`px-2 py-3.5 text-left text-sm font-semibold text-gray-900 ${getCustomColumnStyle(index)}`}
                >
                    {elem}
                </th>
            return headerCell
        })
        const tableHeaderRow =
            <thead>
                <tr>
                    {tableHeaders}
                </tr>
            </thead>

        return tableHeaderRow
    }

    const createTableDesktopBody = () => {
        const drawTableCellContent = (tableCellValue: string, elem: ISustainabilityData, rowNumber: number, columnNumer: number) => {
            if (!isMinerRanking(props.rankingType) && columnNumer === CCRI_CERTIFIED_INVESTOR_INDEX) {
                return (drawCcriCertifiedIcon(elem, rowNumber, true))
            } else if (columnNumer === GRAPH_Y_AXIS_INDEX) {
                return (<div className="px-2 py-2">{tableCellValue}</div>)
            } else {
                return (<div className="px-2 py-2">{formatTableNumber(tableCellValue, columnNumer)}</div>)
            }
        }
        const tableRows = props.data.map((elem: ISustainabilityData, rowNumber: number) => {
            const currentRow = Object.entries(elem);
            const tableRow =
                <tr key={`${currentRow[0]}-${rowNumber}`}>
                    {
                        currentRow.map((tableCell, columnNumer) => {
                            return (<td className={`whitespace-nowrap text-sm text-gray-900 ${getCustomColumnStyle(columnNumer)}`}>{
                                drawTableCellContent(tableCell[1], elem, rowNumber, columnNumer)
                            }</td>)
                        })
                    }
                </tr>
            return tableRow
        })
        return tableRows
    }

    const createTableMobile = () => {

        return (
            <div className="overflow-hidden bg-white shadow sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-200">
                    {createTableMobileContent()}
                </ul>
            </div>
        )
    }

    const createTableMobileContent = () => {
        const drawRowKeyValues = (currentRowKeyValues: [string, string][]) => {
            let result: JSX.Element[] = []
            const row = currentRowKeyValues.reduce((acc, elem, columnNumber) => {
                if (columnNumber !== GRAPH_Y_AXIS_INDEX
                    && columnNumber !== (isMinerRanking(props.rankingType) ? CCRI_CERTIFIED_MINER_INDEX : CCRI_CERTIFIED_INVESTOR_INDEX)
                ) {
                    acc.push(
                        <div className="flex flex-row justify-center md:justify-left gap-x-1">
                            <p className="text-black">
                                {elem[0]}:
                            </p>
                            <p className="text-gray-400">
                                {formatTableNumber(elem[1], columnNumber)}
                            </p>
                        </div>
                    );
                }
                return acc;
            }, result);

            return row
        }

        const tableRows = props.data.map((elem, index) => {
            const currentRowKeyValues = Object.entries(elem);
            return (
                <li key={`miner-holder-row-${index}`}>
                    <div className="flex items-center px-4 pb-4 pt-6 md:px-6">
                        <div className="min-w-0 flex-1 md:flex md:items-center md:justify-between">
                            <div className="truncate">
                                <div className="flex text-normal justify-center md:justify-start">
                                    <p className="truncate text-secondaryblue font-[500]">
                                        {currentRowKeyValues[0][1]}
                                    </p>
                                </div>
                                <div className="mt-2 flex justify-center md:justify-left">
                                    <div className="flex flex-col gap-1 md:flex-row md:flex-wrap md:gap-x-2 items-left text-sm text-gray-500">
                                        {drawRowKeyValues(currentRowKeyValues)}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex-shrink-0 md:mt-0 md:ml-5">
                                <div className="flex justify-center md:justify-start -space-x-1 overflow-hidden">
                                    {drawCcriCertifiedIcon(elem, index, false)}
                                </div>
                            </div>

                        </div>
                    </div>
                </li>
            )
        })
        return tableRows
    }


    return (
        <div className="w-full">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">
                    {createSustainabilityRankingTable()}
                </div>
            </div>

        </div>
    );
};

export default SustainabilityRankingTable



