import React from "react";
import FilterIcon from "../../assets/icons/filter.png";
import XIcon from "../../assets/icons/close.png";

export interface FilterButtonProps {
  text?: string;
  type?: "filter" | "close";
  onClick: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  text,
  type,
  onClick,
}) => {
  return (
    <div
      className={`rounded-full bg-primaryblue text-white border border-white ${
        text ? "px-4 py-2" : "px-2.5 py-5"
      } h-8 flex fley-row w-fit items-center cursor-pointer`}
      onClick={onClick}
    >
      {text ? (
        <span className="font-roboto font-light text-lg mr-4">{text}</span>
      ) : (
        <></>
      )}
      <span
        className={`h-5 w-5 text-white ${
          type && type === "close" ? "p-0.5" : "pt-1"
        }`}
      >
        <img
          src={type && type === "close" ? XIcon : FilterIcon}
          alt="icon"
        ></img>
      </span>
    </div>
  );
};
