import { sustainabilityRankingInvestorTextFragments, sustainabilityRankingInvestorTextLinks, sustainabilityRankingInvestorTextLinkTexts, sustainabilityRankingMinerTextFragments, sustainabilityRankingMinerTextLinks, sustainabilityRankingMinerTextLinkTexts, TRankingType } from "./sustainabilityRankingConstants";
import { Paragraph } from "../../components/Paragraph";
import { useTranslation } from "react-i18next";
import { isMinerRanking } from "./sustainabilityRankingHelpers";


export interface ISustainabilityRankingTextProps {
    rankingType: TRankingType;
}

export const SustainabilityRankingText: React.FC<ISustainabilityRankingTextProps> = (props) => {

    const { t } = useTranslation();

    const getTextForRankingType = () => {
        return isMinerRanking(props.rankingType) ? (
            <Paragraph
                textAlign="center"
                texts={sustainabilityRankingMinerTextFragments}
                linktexts={sustainabilityRankingMinerTextLinkTexts}
                links={sustainabilityRankingMinerTextLinks}
            />
        ) : (
            <Paragraph
                textAlign="center"
                texts={sustainabilityRankingInvestorTextFragments}
                linktexts={sustainabilityRankingInvestorTextLinkTexts}
                links={sustainabilityRankingInvestorTextLinks}
            />
        )
    }

    return (
        <div className="mt-4 md:mt-8">
            {getTextForRankingType()}
        </div>
    )
};

export default SustainabilityRankingText