
import { Coin, TAssetType } from "../typings/CurrencyTypes";


export const filterCoinsByAssetType = (assetType: TAssetType, coinList: Coin[]) => {
    return coinList.filter(
        (coin: Coin) => {
            if (assetType === "token") {
                return coin.type === "token" && !coin.power_model
            } else if (assetType === "pos" || assetType === "pow") {
                return coin.type !== "token" && coin.power_model.toLowerCase().includes(assetType)
            }
        }
    )
}


export const getOriginalCoinAssetType = (coin: Coin): undefined | TAssetType => {
    if (coin.type === "token") {
        return "token"
    } else {
        if (coin.power_model.toLowerCase().includes("pow")) {
            return "pow"
        } else if (coin.power_model.toLowerCase().includes("pos")) {
            return "pos"
        } else {
            return "other"
        }
    }

}


export const getAdjustedCoinAssetType = (coin: Coin): undefined | TAssetType => {
    
    const originalCoinAssetType = getOriginalCoinAssetType(coin)
    
    const changeAssetTypes: {[ticker: string]: TAssetType} = {
        xrp: "other",
        vet: "other",
        xlm: "other",
    }
    
    if (coin.ticker in changeAssetTypes){
        return changeAssetTypes[coin.ticker]
    }else {
        return originalCoinAssetType
    }
}

export const findCurrencyByTicker = (coins: Coin[], ticker: string): Coin => {
    const foundCurrency = coins.find(obj => obj.ticker === ticker);
    return foundCurrency!
}

export const isCoinUnmeasured = (coin: Coin, allCoins: Coin[], unmeasuredCoins: Coin[]): boolean => {
    if (allCoins.includes(coin) && unmeasuredCoins.includes(coin)) {
        return true
    } else {
        return false
    }
}

export const capitalizeUnit = (unit: string) => {
    const unitCapitalizationMapping = {
        "g": "g",
        "kg": "kg",
        "t": "t",
        "mt": "Mt",
        "gt": "Gt",
        'w': 'W',
        'kw': 'kW',
        'mw': 'MW',
        'gw': 'GW',
        'tw': "TW",
        'wh': 'Wh',
        'kwh': 'kWh',
        'mwh': 'MWh',
        'gwh': 'GWh',
        'twh': "TWh",
    } as { [key: string]: string };
    const capitalized = unitCapitalizationMapping[unit];
    return capitalized || unit; // Return capitalized form if available, else return the unit itself
}


export const convertToBaseUnit = (value: number, unit: string) => {
    //base units: kg, kw, kwh
    const conversionFactors: Record<string, number> = {
        W: 0.001,    // Convert W to kW
        kW: 1,       // kW to kW
        MW: 1000,    // Convert MW to kW
        GW: 1000000, // Convert GW to kW
        TW: 1000000000, // Convert TW to kW
        Wh: 0.001,   // Convert Wh to kWh
        kWh: 1,      // kWh to kWh
        MWh: 1000,   // Convert MWh to kWh
        GWh: 1000000, // Convert GWh to kWh
        TWh: 1000000000, // Convert TWh to kWh
        g: 0.001,   // Convert g to kg
        kg: 1,      // kg to kg
        t: 1000,    // Convert t to kg
        Mt: 1000000000, // Convert Mt to kg
        Gt: 1000000000000, // Convert Gt to kg
        Tt: 1000000000000000 // Convert Tt to kg
    };

    const conversionFactor = conversionFactors[unit];
    return value * conversionFactor;
}
