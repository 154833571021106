import React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { HeaderSection } from "../../sections/HeaderSection";
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import { Paragraph } from "../../components/Paragraph";
import { Heading } from "../../components/Heading";

import { IFaq, faqs } from "./faqConstants";
import { Disclosure, Transition } from "@headlessui/react";

export interface FaqProps { }

export const Faq: React.FC<FaqProps> = (props) => {

  const { t } = useTranslation();
  const titles = [t("faq.title1"), t("faq.title2")];

  const faqSection = (title: string, content: IFaq[], index: number) => {
    return (
      <div
        className="mb-16"
        key={`faq${index}`}
      >
        <Heading
          text={title}
          size={"h2"} />

        <div className="mx-auto mt-4 max-w-4xl divide-y-2 divide-gray-200 px-[20px] pb-6 shadow-[rgba(60,_64,_67,_0.3)_0px_1px_2px_0px,_rgba(60,_64,_67,_0.15)_0px_1px_3px_1px] rounded-lg ">
          <dl className="divide-y divide-gray-200 ">
            {content.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6 pb-2">
                {({ open }) => (
                  <>
                    <dt className="text-lg">

                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={`${open ? '-rotate-180' : 'rotate-0'} h-6 w-6 transition-all`}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>

                    </dt>
                    <Transition
                      show={open}
                      enter="transition duration-1000 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <Paragraph
                          texts={faq.answerLinkParts}
                          links={faq.links ? faq.links : []}
                          linktexts={faq.linkTexts ? faq.linkTexts : []}
                          textAlign="left"
                          grayText={true}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>)

  }

  return (
    <div className="min-h-[89vh]">
      <HeaderSection titles={titles}></HeaderSection>
      {/* wrapper for setting safe-area padding on mobile */}
      <div
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
        }}
      >
        <div className="mt-20 px-5 lg:px-0 text-center" >
          <div className="mx-auto max-w-7xl">
            {Object.entries(faqs).map((faqGroup, index) => (
              faqSection(faqGroup[0], faqGroup[1], index)))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
