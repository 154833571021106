import axios from "axios";
import {
  Currency,
  CoinConsumptionData,
  CoinEmissionData,
  CoinTableData,
  Coin,
} from "../typings/CurrencyTypes";
import { findCurrencyByTicker, getOriginalCoinAssetType } from "./currency-helpers";

type TCoinType = "tokens" | "currencies" | "unmeasured"

const backendBaseUrl = process.env.REACT_APP_BACKEND_URL;

const requestSettings = {
  headers: {
    Accept: "application/json",
  },
};

export const getCoinList = async (coinType: TCoinType): Promise<Currency[]> => {

  const postProcessCoinList = (tableData: Currency[]) => {
    return tableData.sort((a: Currency, b: Currency) => (a.name < b.name) ? -1 : 1)
  }

  try {
    let res = await axios.get(
      backendBaseUrl + `api/${coinType}/details`,
      requestSettings
    );
    return postProcessCoinList(res.data);
  } catch (err) {
    throw err;
  }
};

export const getUnmeasuredCoinList = async (): Promise<Coin[]> => {

  try {
    let res = await axios.get(
      backendBaseUrl + `api/unmeasured/details`,
      requestSettings
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

// export const getCurrencyInfo = async (ticker: string): Promise<Currency> => {
//   try {
//     let res = await axios.get(
//       backendBaseUrl + `api/currencies/details/${ticker}`,
//       requestSettings
//     );
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

// export const getCurrencyEmissions = async (
//   ticker: string,
//   start: string,
//   end: string
// ): Promise<CurrencyEmissionData> => {
//   try {
//     let res = await axios.get(
//       backendBaseUrl +
//       `api/currencies/graphdata/emissions/${start}/${end}/${ticker}/`,
//       requestSettings
//     );
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

// export const getCurrencyEnergyConsumptions = async (
//   ticker: string,
//   start: string,
//   end: string
// ): Promise<CurrencyConsumptionData> => {
//   try {
//     let res = await axios.get(
//       backendBaseUrl +
//       `api/currencies/graphdata/consumptions/${start}/${end}/${ticker}/`,
//       requestSettings
//     );
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

// export const getCurrencyListEmissions = async (
//   currencies: Currency[],
//   start: string,
//   end: string
// ): Promise<CurrencyEmissionData[]> => {
//   let data: CurrencyEmissionData[] | PromiseLike<CurrencyEmissionData[]> = [];

//   for (let currency of currencies) {
//     let currency_data = await getCurrencyEmissions(currency.ticker, start, end);
//     data.push(currency_data);
//   }

//   return data;
// };

export const getEthereumEmissions = async (currenciesDetails: Currency[]): Promise<CoinEmissionData[]> => {
  const ethEmissions = (await getCoinEmissions("currencies", currenciesDetails)).filter(elem => elem.ticker === "eth2" || elem.ticker === "eth");
  return ethEmissions;

};

export const getEthereumConsumptions = async (): Promise<CoinConsumptionData[]> => {
  const ethConsumptions = (await getCoinEnergyConsumptions("currencies")).filter(elem => elem.ticker === "eth2" || elem.ticker === "eth");
  return ethConsumptions;
};


export const getAllEmissions = async (coinDetails: Coin[]): Promise<CoinEmissionData[]> => {
  return [...await getCoinEmissions("currencies", coinDetails), ...await getCoinEmissions("tokens", coinDetails)]
}


const getCoinEmissions = async (coinType: TCoinType, coinDetails: Coin[]): Promise<CoinEmissionData[]> => {
  const postProcessEmissionData = (emissionsData: CoinEmissionData[]) => {
    // some pos chains are not in kg, convert them
    emissionsData.forEach((elem) => {
      const assetType = getOriginalCoinAssetType(findCurrencyByTicker(coinDetails, elem.ticker))
      if (assetType == "pos") {
        const changeTickers = ["matic", "eth2"]
        if (changeTickers.includes(elem.ticker)) {
          elem.dailyEmissions = elem.dailyEmissions.map(emission => (emission ? emission * 1000 : emission))
          elem.yearlyEmissions = elem.yearlyEmissions.map(emission => (emission ? emission * 1000 : emission))
        }
      }
    })
    return emissionsData
  }

  try {
    let res = await axios.get(
      backendBaseUrl + `api/${coinType}/graphdata/emissions`,
      requestSettings
    );

    return postProcessEmissionData(res.data);
  } catch (err) {
    throw err;
  }
};


export const getAllEnergyConsumptions = async (): Promise<CoinConsumptionData[]> => {
  return [...await getCoinEnergyConsumptions("currencies"), ...await getCoinEnergyConsumptions("tokens")]
}


const getCoinEnergyConsumptions = async (coinType: TCoinType): Promise<
  CoinConsumptionData[]
> => {
  try {
    let res = await axios.get(
      backendBaseUrl + `api/${coinType}/graphdata/consumptions`,
      requestSettings
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};


// export const getCurrencyListEnergyConsumptions = async (
//   currencies: Currency[],
//   start: string,
//   end: string
// ): Promise<CurrencyConsumptionData[]> => {
//   let data: CurrencyConsumptionData[] | PromiseLike<CurrencyConsumptionData[]> =
//     [];

//   for (let currency of currencies) {
//     let currency_data = await getCurrencyEnergyConsumptions(
//       currency.ticker,
//       start,
//       end
//     );
//     data.push(currency_data);
//   }

//   return data;
// };

export const getAllTableData = async (): Promise<CoinTableData[]> => {
  return [...await getCoinTableData("currencies"), ...await getCoinTableData("tokens")]
}

export const getUnmeasuredTableData = async (): Promise<CoinTableData[]> => {
  return await getCoinTableData("unmeasured")
}

const getCoinTableData = async (coinType: TCoinType): Promise<CoinTableData[]> => {

  const postProcessTableData = (tableData: CoinTableData[]) => {
    return tableData.sort((a: CoinTableData, b: CoinTableData) => (a.marketcap > b.marketcap) ? -1 : 1)
  }

  try {
    let res = await axios.get(
      backendBaseUrl + `api/${coinType}/tabledata`,
      requestSettings
    );
    return postProcessTableData(res.data);
  } catch (err) {
    throw err;
  }
};




