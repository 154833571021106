import { ISustainabilityData, SORT_BY_INVESTOR_INDEX, SORT_BY_MINER_INDEX, TRankingType } from "./sustainabilityRankingConstants"
import * as Papaparse from 'papaparse';


export const getDataForRankingType = (rankingType: TRankingType): Promise<ISustainabilityData[]> | undefined => {
    try {
        const file = require(`../../assets/sustainability_ranking_data/${rankingType}.csv`)

        const result = fetch(file)
            .then(response => response.text())
            .then(responseText => {
                /* parse data */
                const data = Papaparse.parse(responseText, {
                    header: true
                });
                return data.data as unknown as ISustainabilityData[]
            });

        return result
    } catch (error) {
        console.log(`Error parsing sustainability ranking data: ${error}`)
        return undefined
    }
}

export const isMinerRanking = (rankingType: TRankingType): boolean => {
    return rankingType === "miner-ranking" ? true : false;
}

export const postProcessDataForRankingType = (data: ISustainabilityData[], rankingType: TRankingType): ISustainabilityData[] => {

    const sortData = (data: ISustainabilityData[]): ISustainabilityData[] => {
        const sortByKey = isMinerRanking(rankingType) ? getColumnNameByIndex(data, SORT_BY_MINER_INDEX) : getColumnNameByIndex(data, SORT_BY_INVESTOR_INDEX)
        return data.sort((a, b) => (+b[sortByKey] - (+a[sortByKey])))
    }
    const processedData = data.map((elem) => {
        Object.entries(elem).forEach(([key, value]) => {
            elem[key] = value.replace(',', '')
        });
        return elem
    })
    return sortData(processedData)
}

export const getColumnNameByIndex = (data: ISustainabilityData[], index: number) => {
    return Object.keys(data[0])[index]
}

