// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import ccriLogo from "../../assets/CCRI-logo-black.png";
//import ccriWaterMark from "../../assets/ccri-watermark.png";
import { CoinGraphData } from "../../typings/CurrencyTypes";
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  TimeScale,
  LinearScale,
  LogarithmicScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);
//ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = "Roboto";

export interface CurrencyGraphProps {
  currentAssetType: string;
  graphDataType: string;
  data: CoinGraphData;
  isMergePage?: boolean;
}

export const CurrencyGraph: React.FC<CurrencyGraphProps> = ({
  currentAssetType,
  graphDataType,
  data,
  isMergePage,
}) => {
  const { t } = useTranslation();
  const [width, getWidth] = useState(window.innerWidth);
  const ethMergeDate = "2022-09-15"

  const setWidth = () => {
    getWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [width]);

  const displayMergeAnnotation = () => {
    return isMergePage
      ? (new Date(data.labels[0]) <= new Date(ethMergeDate))
      : false
  }

  // const adjustDataUnits = () => {

  //   const convertArray = (array, multiplyBy) => {
  //     return array.map(value => value * multiplyBy);
  //   }

  //   const dataConverted = { ...data };
  //   const datasets = dataConverted.datasets;
  //   if (currentAssetType = "pos") {
  //     datasets.forEach((dataset) => {
  //       if (graphDataType === "consumption") {

  //       } else if (graphDataType === "emission") {
  //         if (dataset.label === 'Polygon') {
  //           console.log(dataset.data)
  //           dataset.data = convertArray(dataset.data, 1000);
  //           console.log(dataset.data)
  //         }
  //       }
  //     })
  //   }

  //   return dataConverted
  // }

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    response: true,
    plugins: {
      legend: {
        position: "bottom",
        // position: width > 1024 ? ("right" as const) : ("bottom" as const),
        onClick: () => undefined
      },
      tooltip: {
        backgroundColor: "#283044",
        intersect: false,
        position: "nearest",
        titleFont: { weight: "light", family: "Roboto" },
        cornerRadius: 0,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              // label += (context.parsed.y).toLocaleString("en-US");
              const labelValue = context.parsed.y;
              if (currentAssetType === "pow") {
                label += labelValue.toLocaleString("en-US", { maximumFractionDigits: label.split(" ")[1].split(":")[0] == "PoS" ? 4 : 2, minimumFractionDigits: label.split(" ")[1].split(":")[0] == "PoS" ? 4 : 2 });
                graphDataType === "consumption"
                  ? (label += " TWh")
                  : (label += " Mt CO\u2082")
              } else {
                label += (labelValue / 1000).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                graphDataType === "consumption"
                  ? (label += " MWh")
                  : (label += " t CO\u2082")
              }
            }
            return label;
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            display: displayMergeAnnotation(),
            drawtime: "afterDatasetsDraw",
            type: "line",
            scaleID: "x",
            value: ethMergeDate,
            borderWidth: 2,
            backgroundColor: "rgba(40, 48, 68, 0.7)",
            label: {
              drawTime: 'afterDatasetsDraw',
              display: true,
              position: "start",
              content: "The Merge",
              backgroundColor: 'rgba(245,245,245)',
              color: "rgba(0,0,0)",
            },
          },
        }
      },
    },
    interaction: {
      mode: "nearest",
    },
    scales: {
      x: {
        type: "time",
        time: {
          round: "day",
          tooltipFormat: "dd/MM/yyyy",
          displayFormats: {
            day: 'MMM dd, yy',
            week: 'MMM yy',
            month: 'MMM yy',
            quarter: 'MMM yy',
            year: 'yyy',
          },
        },
      },
      y: {
        ticks: isMergePage ? {
          callback: function (value) {
            const ticks = [0, 0.01, 0.1, 1, 10, 100, 1000]
            if (ticks.includes(value)) {
              return value;
            }
          }
        }
          : {
            callback: function (value) {
              if (currentAssetType === "pos" || currentAssetType == "token") {
                return (value / 1000).toLocaleString("en-US");
              } else {
                return value.toLocaleString("en-US");
              }
            },
          },
        min: isMergePage ? 0 : undefined,
        max: isMergePage ? 100 : undefined,
        type: isMergePage ? "logarithmic" : "linear",
        grace: isMergePage ? "70%" : "30%",
        beginAtZero: true,
        title: {
          display: true,
          font: {
            size: width > 1024 ? 16 : 11,
          },
          text: isMergePage ?
            (graphDataType === "consumption" ?
              t("general-dashboard.graph.consumption-eth-merge")
              : t("general-dashboard.graph.emission-eth-merge"))
            : (
              currentAssetType === "pow"
                ? graphDataType === "consumption"
                  ? width > 1024
                    ? t("general-dashboard.graph.consumption-pow")
                    : t("general-dashboard.graph.consumption-pow-mobile")
                  : width > 1024
                    ? t("general-dashboard.graph.emission-pow")
                    : t("general-dashboard.graph.emission-pow-mobile")
                : graphDataType === "consumption"
                  ? width > 1024
                    ? t("general-dashboard.graph.consumption-pos")
                    : t("general-dashboard.graph.consumption-pos-mobile")
                  : width > 1024
                    ? t("general-dashboard.graph.emission-pos")
                    : t("general-dashboard.graph.emission-pos-mobile")
            ),
        },
      },
    },
  };
  return (
    // <div className="w-full flex justify-center items-center">
    //   <div className="relative w-full z-0">
    //     <div className="w-full absolute inset-0 flex justify-center items-center z-10">
    //       <Line options={graphOptions} data={data} />
    //     </div>
    //     <div className="flex justify-center items-center lg:h-[600px] md:h-[400px] h-[500px] lg:pr-44">
    //       <img className="opacity-25" src={ccriWaterMark} alt="CCRI"></img>
    //     </div>
    //   </div>
    // </div>
    <div className="w-full flex justify-center items-center">
      <div className="relative w-full z-0 lg:h-[600px] md:h-[400px] h-[380px]">
        <div className="absolute inset-0 flex justify-start items-start  pointer-events-none lg:pt-8 pt-6 lg:pl-24 pl-16">
          <div className="lg:w-32 md:w-24 w-20 bg-white lg:px-5 md:px-4 px-2 lg:py-3.5 md:py-2 py-1 border border-solid border-mediumgrey rounded-full">
            <img src={ccriLogo} alt="CCRI"></img>
          </div>
        </div>
        <div className="w-full h-full">
          <Line options={graphOptions} data={data} />
        </div>
      </div>
    </div>
  );
};
