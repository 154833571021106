export const dataMethodsPowTextFragments = [
    "data-methods.text-pow1",
    "data-methods.text-pow2",
    "data-methods.text-pow3",
    "data-methods.text-pow4",
];

export const dataMethodsPowTextLinkTexts = [
    "data-methods.text-pow-link1",
    "data-methods.text-pow-link2",
    "data-methods.text-pow-link3",
];

export const dataMethodsPowTextLinks = [
    "https://ccaf.io/cbeci/index",
    "https://www.sciencedirect.com/science/article/pii/S2542435120303317",
    "https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861",
];


export const dataMethodsPoSTextFragments = [
    "data-methods.text-pos1",
    "data-methods.text-pos2",
];

export const dataMethodsPoSTextLinkTexts = [
    "data-methods.text-pos-link1",
];

export const dataMethodsPoSTextLinks = [
    "https://carbon-ratings.com/tron-report-2022",
];


export const dataMethodsPoWTextFootnotes = "data-methods.text-pow-footnote1";

export const dataMethodsPoSTextFootnotes = "data-methods.text-pos-footnote1";

export interface ILinkTextStructure {
    texts: string[];
    links: string[];
    linkTexts: string[];
}

export interface ICurrencyDataInfo {
    [infoKey: string]: string | ILinkTextStructure
}

export interface ICurrenciesDataInfo {
    [ticker: string]: ICurrencyDataInfo
}

export const currenciesInfo: ICurrenciesDataInfo =
{
    "btc": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["", ""], links: ["https://ccaf.io/cbeci/index"], linkTexts: ["Cambridge Bitcoin Electricity Consumption Index"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }
    },
    "eth": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Etherscan.io Hashrate",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }
    },
    "doge": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] },
        "Notice": "Dogecoin and Litecoin support Mergemining. Therefore, we distribute the hashrate between the networks depending on their mining reward.",
    },
    "ltc": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] },
        "Notice": "Dogecoin and Litecoin support Mergemining. Therefore, we distribute the hashrate between the networks depending on their mining reward.",

    },
    "bch": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }

    },
    "bsv": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }

    },
    "zec": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "TBD",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }

    },
    "dash": {
        "Block information": { texts: ["", ""], links: ["https://blockchair.com/dumps#nodes"], linkTexts: ["Blockchair's Full Node dumps"] },
        "Hash rate": "Own calculations on block information",
        "Electricity consumption": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/pii/S2542435120303317", "https://whattomine.com/"], linkTexts: ["Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2020). Energy consumption of cryptocurrencies beyond bitcoin. Joule, 4(9), 1843-1846.", "whattomine.com"] },
        "Carbon footprint": { texts: ["Perpetuation of ", " with data from "], links: ["https://www.sciencedirect.com/science/article/abs/pii/S2542435122000861", "https://ccaf.io/cbeci/mining_map"], linkTexts: ["de Vries, A., Gallersdörfer, U., Klaaßen, L., & Stoll, C. (2022). Revisiting Bitcoin’s carbon footprint. Joule, 6(3), 498-502.", "Cambridge Centre fro Alternative Finance Bitcoin Mining Map"] }
    },
    "eth2": {
        "Validator count": { texts: ["", ""], links: ["https://migalabs.es/beaconnodes"], linkTexts: ["migalabs.es"] },
        "Energy consumption": { texts: ["Based on ", " (September 2022)"], links: ["https://carbon-ratings.com/eth-report-2022"], linkTexts: ["CCRI ETH Merge Report"] },
        "Carbon Footprint": { texts: ["Weighted emission factors on ", ""], links: ["https://migalabs.es/beaconnodes"], linkTexts: ["migalabs.es"] },
    },
    "ada": {
        "Validator count": { texts: ["", ""], links: ["https://cardanoscan.io/"], linkTexts: ["cardanoscan.io"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "sol": {
        "Validator count": { texts: ["", ""], links: ["https://solanabeach.io/"], linkTexts: ["solanabeach.io"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "dot": {
        "Validator count": { texts: ["", ""], links: ["https://polkadot.subscan.io/validator"], linkTexts: ["subscan.io"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "matic": {
        "Validator count": "Constant at 100 validators (x2)",
        "Transaction count": "TBD",
        "Energy consumption": { texts: ["Based on ", " (August 2022)"], links: ["https://carbon-ratings.com/polygon-report-2022"], linkTexts: ["CCRI Polygon Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "trx": {
        "Validator count": { texts: ["", ""], links: ["https://tronscan.org"], linkTexts: ["tronscan.org"] },
        "Transaction count": "TBD",
        "Energy consumption": { texts: ["Based on ", " (August 2022)"], links: ["https://carbon-ratings.com/tron-report-2022"], linkTexts: ["CCRI TRON Report"] },
        "Carbon Footprint": { texts: ["Weighted emission factors on ", ""], links: ["https://tronscan.org/#/blockchain/nodes"], linkTexts: ["tronscan.org"] },
    },
    "avax": {
        "Validator count": { texts: ["", ""], links: ["https://www.avax.network/validators"], linkTexts: ["avax.etwork"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "algo": {
        "Validator count": { texts: ["", ""], links: ["https://metrics.algorand.org/#/decentralization/"], linkTexts: ["algorand.org"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
    "xtz": {
        "Validator count": { texts: ["", ""], links: ["https://tzstats.com/bakers"], linkTexts: ["tzstats.com"] },
        "Energy consumption": { texts: ["Based on ", " (March 2022)"], links: ["https://carbon-ratings.com/pos-report-2022"], linkTexts: ["CCRI Proof of Stake Report"] },
        "Carbon Footprint": "IEA World Average Emission Intensity (459g CO2/kWh)",
    },
}

