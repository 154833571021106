import { selectorTabs, TRankingType as TRankingType } from "./sustainabilityRankingConstants"

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface ISustainabilityRankingSelectorTabsProps {
    selectedTab: TRankingType
    changeSelectedTab: (newTab: TRankingType) => void
}


export const SustainabilityRankingSelectorTabs: React.FC<ISustainabilityRankingSelectorTabsProps> = (props) => {

    const onChangeSelectorTab = (selectedLabel: TRankingType) => {
        props.changeSelectedTab(selectedLabel)
    }

    return (
        <div>
            {/* 
            TODO better mobile version of tabs, but only working when popup for investor ranking is removed.
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={props.selectedTab}
                >
                    {selectorTabs.map((tab) => (
                        <option key={tab.id}>{tab.label}</option>
                    ))}
                </select>
            </div> 
            <div className="hidden sm:block mb-8">
            */}
            <div className="block mb-8">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex justify-center space-x-8" aria-label="Tabs">
                        {selectorTabs.map((tab) => (
                            <a
                                key={tab.id}
                                // href={tab.href}
                                onClick={() => onChangeSelectorTab(tab.id)}
                                className={classNames(
                                    tab.id === props.selectedTab
                                        ? 'border-primaryblue text-primaryblue '
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'whitespace-nowrap border-b-2 py-2 px-1 text-base cursor-pointer transition duration-300 ease-in-out'
                                )}
                            >
                                {tab.label}
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
        </div >
    )
}

export default SustainabilityRankingSelectorTabs