import React, { useEffect, useState } from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"
import { HeaderSection } from "../../sections/HeaderSection";
import { ISustainabilityData, TRankingType } from "./sustainabilityRankingConstants";
import { getDataForRankingType, postProcessDataForRankingType } from "./sustainabilityRankingHelpers"
import { LoadingSpinner } from "../../components/LoadingSpinner";
import SustainabilityRankingSelectorTabs from "./SustainabilityRankingSelectorTabs";
import SustainabilityRankingGraph from "./SustainabilityRankingGraph";
import SustainabilityRankingTable from "./SustainabilityRankingTable";
import SustainabilityRankingText from "./SustainabilityRankingText";
import SustainabilityRankingContact from "./SustainabilityRankingContact";
import { TokensModal } from "../../components/TokensModal";


export const SustainabilityRanking: React.FC<{}> = () => {

  const { t } = useTranslation();

  const [selectedRanking, setSelectedRanking] = useState<TRankingType>("miner-ranking");
  const [selectedRatingData, setSelectedRatingData] = useState<ISustainabilityData[] | undefined>(undefined)
  const [selectedRatingDataLoaded, setSelectedRatingDataLoaded] = useState<Boolean>(false)

  //TODO remove:
  //handle tokens modal open
  const [tokensModalOpen, setTokensModalOpen] = useState(false);
  const toggleTokensModalOpen = (isOpen: boolean) => {
    setTokensModalOpen(isOpen)
  }

  const onChangeSelectedRanking = (newSelectedRanking: TRankingType) => {
    //TODO remove if clause and only keep setSelectedRanking(newSelectedRanking)
    if (newSelectedRanking === "investor-ranking") {
      setTokensModalOpen(true)
    } else {
      setSelectedRanking(newSelectedRanking)
    }
    //setSelectedRanking(newSelectedRanking)
  }

  useEffect(() => {
    setSelectedRatingDataLoaded(false)
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getDataForRankingType(selectedRanking);
      if (data) {
        const processedData = postProcessDataForRankingType(data, selectedRanking)
        setSelectedRatingData(processedData)
        setSelectedRatingDataLoaded(true)
      }
    }
    // call the function and make sure to catch any error
    fetchData().catch(console.error);
  }, [selectedRanking]);


  return (
    <div className="min-h-[89vh]">
      <HeaderSection
        titles={[t("sustainability-ranking.title1")]}
        subtitle={t("sustainability-ranking.heading")}
      ></HeaderSection>
      {/* wrapper for setting safe-area padding on mobile */}
      <div className="mt-8 lg:px-20 max-w-screen-2xl ml-auto mr-auto"
      >
        <div className="mt-2 px-5 px-8 lg:px-0 text-center" >
          <div className="mb-12">
            <SustainabilityRankingSelectorTabs
              selectedTab={selectedRanking}
              changeSelectedTab={onChangeSelectedRanking}
            />
            {/* TODO remove Modal: */}
            <TokensModal
              text="the investor ranking"
              isOpen={tokensModalOpen}
              onSetIsOpen={toggleTokensModalOpen} />
            <div>
              {selectedRatingDataLoaded ?
                (<motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                ><div className="flex flex-col gap-y-4">
                    <SustainabilityRankingGraph
                      data={selectedRatingData!}
                      rankingType={selectedRanking} />
                    <SustainabilityRankingTable
                      data={selectedRatingData!}
                      rankingType={selectedRanking} />
                    <SustainabilityRankingText
                      rankingType={selectedRanking} />
                    <SustainabilityRankingContact
                      rankingType={selectedRanking} />
                  </div>
                </motion.div>)
                : <LoadingSpinner
                  loading={true} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityRanking;
