import React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { HeaderSection } from "../../sections/HeaderSection";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import CCRILogo from "../../assets/CCRI-logo-black.png"

export interface AboutProps { }

export const About: React.FC<AboutProps> = (props) => {
  const { t } = useTranslation();
  const titles = ["about.title1"];
  return (
    <div className="min-h-[89vh]">
      <HeaderSection titles={titles}></HeaderSection>
      {/* wrapper for setting safe-area padding on mobile */}
      <div className="mt-16 lg:px-20 max-w-screen-2xl ml-auto mr-auto">
        <div className="px-5 sm:px-6 lg:px-16 pt-4 pb-8 mt-8 md:mt-16 mx-8 lg:mx-8 mb-0 pb-4 rounded-xl shadow-[rgba(14,_30,_37,_0.12)_0px_2px_4px_0px,_rgba(14,_30,_37,_0.32)_0px_2px_16px_0px] bg-[#f1f9ffb3]">
          <div className="flex gap-y-8 gap-x-16 justify-center flex-col lg:flex-row mb-16 lg:mb-8">
            <div className="basis-64 shrink-0 flex justify-center self-center">
              <img
                className="object-scale-down"
                src={CCRILogo}
                alt="CCRI Ethereum Report Cover">
              </img>
            </div>
            <div className="basis-auto shrink flex items-center">
              <Paragraph
                textAlign="left"
                text={t("about.about-text")}
              ></Paragraph>
            </div>
          </div>
          <div className="flex justify-center xl:justify-end">
            <Button
              text={t("about.cta")}
              onClick={() => {
                window.open("https://carbon-ratings.us14.list-manage.com/subscribe/post?u=cc0ac2eba0b20db94ed567362&id=7bdf13a7a8", "_blank");
              }}
              type="cta"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
