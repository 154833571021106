export type TRankingType = "miner-ranking" | "investor-ranking"

export interface ISustainabilityData {
    [key: string]: string;
}

export interface ISelectorTab {
    id: TRankingType,
    label: string,
}

export const selectorTabs: ISelectorTab[] = [{ id: "miner-ranking", label: "Miner Ranking" }, { id: "investor-ranking", label: "Investor Ranking" }]

export const GRAPH_Y_AXIS_INDEX = 0;
export const GRAPH_X_AXIS_MINER_INDEX = 4;
export const GRAPH_X_AXIS_INVESTOR_INDEX = 2;
export const CCRI_CERTIFIED_MINER_INDEX = -1;
export const CCRI_CERTIFIED_INVESTOR_INDEX = 4;
export const SORT_BY_MINER_INDEX = GRAPH_X_AXIS_MINER_INDEX;
export const SORT_BY_INVESTOR_INDEX = GRAPH_X_AXIS_INVESTOR_INDEX;

export const tableValueDecimalsPerColumnNumber = {
    // col num: digits 
    "miner-ranking": {
        1: 1,
        2: 0,
        3: 0,
        4: 0,
        5: 2,
    },
    "investor-ranking": {

    }
}


export const sustainabilityRankingMinerTextFragments = [
    "sustainability-ranking.text-miner-1",
    "sustainability-ranking.text-miner-2",
    "sustainability-ranking.text-miner-3",
    "sustainability-ranking.text-miner-4",
    "sustainability-ranking.text-miner-5",
    "sustainability-ranking.text-miner-6",
    "sustainability-ranking.text-miner-7",
];
export const sustainabilityRankingMinerTextLinkTexts = [
    "sustainability-ranking.text-miner-link1",
    "sustainability-ranking.text-miner-link2",
    "sustainability-ranking.text-miner-link3",
    "sustainability-ranking.text-miner-link4",
    "sustainability-ranking.text-miner-link5",
    "sustainability-ranking.text-miner-link6",
];
export const sustainabilityRankingMinerTextLinks = [
    "https://ghgprotocol.org/scope_2_guidance",
    "https://www.epa.gov/egrid/download-data",
    "https://www.canada.ca/en/environment-climate-change/services/climate-change/pricing-pollution-how-it-will-work/output-based-pricing-system/federal-greenhouse-gas-offset-system/emission-factors-reference-values.html",
    "https://www.iea.org/countries/paraguay",
    "https://www.climate-transparency.org/countries/americas/argentina",
    "https://www.eea.europa.eu/ims/greenhouse-gas-emission-intensity-of-1"
];
export const sustainabilityRankingInvestorTextFragments = [
];
export const sustainabilityRankingInvestorTextLinkTexts = [
];
export const sustainabilityRankingInvestorTextLinks = [
];

export const chartHeigtMinerPx = "h-[350px]";
export const chartHeigtInvestorPx = "h-[500px]";