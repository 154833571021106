import React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";

export interface ParagraphProps {
  text?: string;
  texts?: string[];
  links?: string[];
  linktexts?: string[];
  textAlign?: "left" | "center";
  containerJustify?: "normal" | "center" | "end"
  imgCaption?: boolean;
  grayText?: boolean;
  customSize?: string;
  customStyle?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  text,
  texts,
  links,
  linktexts,
  textAlign,
  containerJustify,
  imgCaption,
  grayText,
  customSize,
  customStyle,
}) => {
  const { t } = useTranslation();

  let content: JSX.Element;
  let textFragments: JSX.Element[];
  let fullLinks: JSX.Element[];

  if (text) {
    content = <>{text}</>;
  } else if (
    texts &&
    texts.length > 0 &&
    links &&
    linktexts &&
    links.length === linktexts.length
  ) {
    textFragments = texts.map((text) => <>{t(text)}</>);
    fullLinks = links.map((link) => (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="underline underline-offset-2 hover:text-secondaryblue"
      >
        {t(linktexts[links.indexOf(link)])}
      </a>
    ));
    content = (
      <>
        {textFragments.map((fragment, i) => (
          <span key={i}>
            {fragment} {fullLinks[i]}
          </span>
        ))}
      </>
    );
  } else {
    content = <></>;
  }

  return (
    <div className={`flex ${containerJustify && `justify-${containerJustify}`}`}>
      <p
        className={`font-roboto ${textAlign && textAlign === "left" ? "text-left" : "text-center"
          } max-w-7xl ${customSize ? customSize : (imgCaption ? "text-sm leading-tight" : "text-base md:text-lg leading-9")} ${grayText ? "text-gray-500" : "text-primaryblue"} ${customStyle && customStyle}`}
      >
        {content}
      </p>
    </div>
  );
};

