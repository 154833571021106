import React, { useState } from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { HeaderSection } from "../../sections/HeaderSection";
import { Paragraph } from "../../components/Paragraph";
import { Heading } from "../../components/Heading";

import {
  dataMethodsPowTextFragments,
  dataMethodsPowTextLinkTexts,
  dataMethodsPowTextLinks,
  dataMethodsPoWTextFootnotes,
  dataMethodsPoSTextFragments,
  dataMethodsPoSTextLinkTexts,
  dataMethodsPoSTextLinks,
  dataMethodsPoSTextFootnotes,
} from "./dataMethodConstants";
import { DataMethodsSelector } from "../../components/DataMethodsSelector";


export const DataMethods: React.FC<{}> = () => {

  const { t } = useTranslation();
  const titles = ["data-methods.title1", "data-methods.title2"];

  // const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined);
  // const [selectedCurrencyPoS, setSelectedCurrencyPoS] = useState("eth2");

  // const onChangeSelectedCurrency = (newTicker: string) => {
  //   // if (type === "pow")
  //     setSelectedCurrency(newTicker);
  //   // else if (type === "pos")
  //     // setSelectedCurrencyPoS(newTicker);
  // }


  return (
    <div className="min-h-[89vh]">
      <HeaderSection titles={titles}></HeaderSection>
      {/* wrapper for setting safe-area padding on mobile */}
      <div className="mt-16 lg:px-20 max-w-screen-2xl ml-auto mr-auto"
      >
        <div className="mt-20 px-5 px-8 lg:px-0 text-center" >
          <div className="mb-24">
            <div className="mb-4">
              <Heading
                text={t("data-methods.subtitle1")}
                size="h2"
              />
            </div>
            <div>
              <Paragraph
                texts={dataMethodsPowTextFragments}
                linktexts={dataMethodsPowTextLinkTexts}
                links={dataMethodsPowTextLinks}
              ></Paragraph>
            </div>
            <div className="mt-2">
              <Paragraph
                text={t(dataMethodsPoWTextFootnotes)}
                textAlign={"center"}
                imgCaption={true}
                grayText={true}
                containerJustify="end"
              ></Paragraph>
            </div>
          </div>
          <div className="mb-24">
            <div className="mb-4">
              <Heading
                text={t("data-methods.subtitle2")}
                size="h2"
              />
            </div>
            <div>
              <Paragraph
                texts={dataMethodsPoSTextFragments}
                linktexts={dataMethodsPoSTextLinkTexts}
                links={dataMethodsPoSTextLinks}
              ></Paragraph>
            </div>
            <div className="mt-2">
              <Paragraph
                text={t(dataMethodsPoSTextFootnotes)}
                textAlign={"center"}
                imgCaption={true}
                grayText={true}
                containerJustify="end"
              ></Paragraph>

            </div>
          </div>
          <div className="mb-24">
            <div>
              <div className="mb-4">
                <Heading
                  text={t("data-methods.subtitle3")}
                  size="h2"
                />
              </div>
              <DataMethodsSelector
                // selectedTicker={selectedCurrency}
                // onCurrencySelect={onChangeSelectedCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataMethods;
