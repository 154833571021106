export interface IFaqs {
    [key: string]: IFaq[]
}

export interface IFaq {
    question: string;
    answerLinkParts: string[];
    links?: string[]
    linkTexts?: string[];
}

export const faqs: IFaqs = {
    "About:": [
        {
            question: "Who is maintaining the CCRI Crypto Sustainability Indices?",
            answerLinkParts: ["The CCRI Crypto Sustainability Indices are maintained by the team of CCRI GmbH, a research-driven company that provides sustainability metrics for cryptocurrencies."]
        },
        {
            question: "Who is CCRI?",
            answerLinkParts: ["CCRI – Crypto Carbon Ratings Institute – is a research-driven company providing data on sustainability aspects of cryptocurrencies, blockchain and other technologies. The interdisciplinary team has built a multi-year research track record with a specific focus on cryptocurrencies and their sustainability impacts. CCRI uses the most up-to-date data sources as well as methods based on formerly peer-reviewed studies published in renowned scientific journals. CCRI provides insights that help their clients to understand and manage crypto-related ESG exposure. They serve a broad range of clients including institutional investors, exchanges and blockchain networks."]
        },
        {
            question: "How frequently is the website updated?",
            answerLinkParts: ["All indices are updated every 24 hours. In case of technical issues, values for single indices might be delayed. CCRI services are subject to extensive monitoring and you can check ", " whether technical problems occur."],
            links: ["https://status.carbon-ratings.com/"],
            linkTexts: ["here"]
        },
        {
            question: "What is the license for using this data?",
            answerLinkParts: ["The data of this website is free to use for private and non-commercial scientific research. It is licensed under ", ", proper attribution required. Please cite as \"CCRI (2022). Crypto Sustainability Indices. Available at ", ".\". In case you have a commercial interest, please reach out to us."],
            links: ["https://creativecommons.org/licenses/by-nc-sa/4.0/", "https://indices.carbon-ratings.com"],
            linkTexts: ["CC BY-NC-SA 4.0", "https\uA789//indices.carbon-ratings.com"]
        },
        {
            question: "Are there planned updates for the CCRI indices?",
            answerLinkParts: ["CCRI is constantly improving and extending the indices to reflect our newest insights. Sign up for the newsletter ", " to stay updated on upcoming features."],
            links: ["https://carbon-ratings.us14.list-manage.com/subscribe/post?u=cc0ac2eba0b20db94ed567362&id=7bdf13a7a8"],
            linkTexts: ["here"]
        },
    ],
    "Indices:": [
        {
            question: "On which data points do the indices rely on?",
            answerLinkParts: ["The CCRI Crypto Sustainability Indices are a direct product of the CCRI Crypto Sustainability API that allows direct access to emission data of cryptocurrencies. To understand which data points the API relies on, please head over to the ", "."],
            links: ["https://docs.api.carbon-ratings.com/#/"],
            linkTexts: ["API Documentation"]
        },
        {
            question: "What is the CCRI Crypto Sustainability API?",
            answerLinkParts: ["The CCRI Crypto Sustainability API provides up-to-date metrics for cryptocurrency CO\u2082 emissions. Instead of manually estimating, calculating and attributing network emissions to activities (such as holdings or transactions), the CCRI Crypto Sustainability API is always on par with latest research on the environmental impact of cryptocurrencies. For more information please refer to the ", " section."],
            links: ["https://indices.carbon-ratings.com/data-methods"],
            linkTexts: ["Data & Methods"]
        },
        {
            question: "What means peer-reviewed and are the indices peer-reviewed?",
            answerLinkParts: ["Peer reviewing is a common practice in academic research. In the course of the academic peer review process, a paper is feedbacked by independent reviewers. The process aims to ensure the quality of a paper as papers that do not satisfy the reviewers do not succeed in publication. All journal publications mentioned in the ", " section have been subject to extensive peer review. As such, the data points of our indices either rely on peer-reviewed scientific literature or on industry reports published by CCRI on the individual cryptocurrency. As peer review is not a common practice outside of academic research, our industry reports are not peer-reviewed. However, we provide a detailed data collection and method description in all reports to be as transparent as possible and welcome any kind of feedback on our approaches."],
            links: ["https://indices.carbon-ratings.com/data-methods"],
            linkTexts: ["Data & Methods"]
        },
        {
            question: "Why do the CO\u2082 emissions not directly correlate with the electricity consumption of a network?",
            answerLinkParts: ["Often, the emission intensity of the used electricity changes over time. Depending on the underlying data, the CO\u2082 emissions can increase or decrease relative to the electricity consumption."]
        },
        {
            question: "When are you including new currencies in the indices?",
            answerLinkParts: ["We are constantly including new currencies based on new insights and commissioned reports. If you are interested in commissioning a new report for a cryptocurrency, please get in touch."]
        },
        {
            question: "Why does the data point for Ethereum end on the 15th September 2022?",
            answerLinkParts: ["Ethereum has changed it's conensus mechanism from Proof of Work to Proof of Stake during the Merge event in September 2022. Therefore, there are two separate indices for Ethereum Proof of Work (ending on 15th September 2022) and Ethereum Proof of Stake (beginning on 15th September 2022)."]
        },
    ]
}

