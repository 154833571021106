import React, { useState } from "react";

export interface TimespanSelectorButtonProps {
  month: number;
  text: string;
  isActive: boolean;
  isPopup: boolean;
  setFixedTimeFrame: (month: number) => void;
}

const commonClasses =
  "transition duration-500 ease-in-out flex items-center text-center border-b-2 font-roboto relative overflow-hidden py-2 xsm:w-full w-[155px] cursor-pointer";
const selectedCommon = "hover:text-white hover:bg-primaryblue border-primaryblue"
const selectedClassesMainpage = "text-primaryblue bg-buttonUnhovered"
const selectedClassesPopup = "text-white bg-primaryblue"
const unselectedClasses =
  "text-primaryblue bg-buttonUnhovered border-buttonUnhovered hover:bg-primaryblue hover:text-white";

export const TimespanSelectorButton: React.FC<TimespanSelectorButtonProps> = (props) => {


  return (
    <div className="relative z-0 flex" aria-label="Tabs">
      <div
        key={props.month}
        className={`${commonClasses} ${props.isActive
          ? `${selectedCommon} ${props.isPopup ? selectedClassesPopup : selectedClassesMainpage}`
          : unselectedClasses
          } 
           `}
        onClick={() => props.setFixedTimeFrame(props.month)}
      >
        <span className="mx-auto">{props.text}</span>
      </div>
    </div>
  );
};
