import { QuestionMarkCircleIcon} from '@heroicons/react/20/solid'


interface IHoverTooltipProps {
    text: string
}


export const HoverTooltip: React.FC<IHoverTooltipProps> = (props) => {


    return (
        <div className="group relative flex justify-center overflow-visible	flex flex-grow">
            <QuestionMarkCircleIcon className='w-5 h-5 text-primaryblue'/>
            <div className="inline-block w-max absolute top-[-40px] scale-0 transition-all rounded bg-primaryblue bg-opacity-90 py-2 px-4 text-xs text-white group-hover:scale-100">{props.text}</div>
        </div>
    )
}


