import React from "react";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { Navlink, NavlinkProps } from "../Navlink";
import ccriLogo from "../../assets/CCRI-logo.png";

export interface NavbarProps {
  links: Array<NavlinkProps>;
}

export const Navbar: React.FC<NavbarProps> = (props) => {
  const location = useLocation();

  const isLinkActive = (linkProps: any) => {
    return location.pathname.includes(
      linkProps.text.toLowerCase())
      || (linkProps.to === location.pathname)
      || (linkProps.text === "Indices" && location.pathname === "/")
      ? true
      : false
  }

  return (
    <Disclosure
      as="nav"
      className="bg-primaryblue shadow"
      style={{
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      }}
    >
      {({ open }) => (
        <>
          <div className={`mx-auto px-2 sm:px-6 2xl:px-28`}>
            <div className="relative flex justify-between h-16">
              <div className="flex-1 flex items-center sm:items-stretch sm:justify-between">
                <div className="flex-shrink-0 flex items-center">
                  <a href="/">
                    <img
                      className="block xl:hidden h-10 w-auto"
                      src={ccriLogo}
                      alt="CCRI"
                    />
                    <img
                      className="hidden xl:block h-9 w-auto"
                      src={ccriLogo}
                      alt="CCRI"
                    />
                  </a>
                </div>
                <div className="hidden xl:ml-6 xl:flex xl:space-x-8">
                  {props.links.map((linkProps) => {
                    return (
                      <Navlink
                        {...linkProps}
                        type="menu"
                        key={linkProps.text}
                        active={
                          isLinkActive(linkProps)
                        }
                      ></Navlink>
                    );
                  })}
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center xl:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md ">
                    {open ? (
                      <XMarkIcon
                        className="block h-10 w-10 text-mediumgrey hover:text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon
                        className="block h-10 w-10 text-mediumgrey hover:text-white"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="xl:hidden">
            <div className="space-y-1">
              {props.links.map((linkProps) => {
                return (
                  <Navlink
                    {...linkProps}
                    type="mobile-menu"
                    key={linkProps.text}
                    active={
                      isLinkActive(linkProps)
                    }
                  ></Navlink>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
