import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../i18n";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState, actionCreators } from "../../state";
import { bindActionCreators } from "redux";

// typings
import {
  CoinGraphData,
  CoinTableData,
  CoinEmissionData,
  CoinConsumptionData,
  TAssetType as TAssetType,
} from "../../typings/CurrencyTypes";

// components
import { CurrencyGraph } from "../../components/CurrencyGraph";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/Button";
import { Paragraph } from "../../components/Paragraph";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { HeaderSection } from "../../sections/HeaderSection";
import { DashboardControls } from "../../sections/DashboardControls";
import { FilterButton } from "../../components/FilterButton";

// functions/services
import {
  getAllTableData,
  getAllEnergyConsumptions,
  getAllEmissions,
  getUnmeasuredTableData,
} from "../../services/currency-backend-service";
import {
  defaultColor,
  chartTableTextLinks,
  chartTableTextFragments,
  chartTableTextLinkTexts,
  blueColors,
  assetTypesMapping,
} from "./dashboardConstants";
import {
  getDatesBetween,
  fitConsumptionGraphDataToDates,
  fitEmissionGraphDataToDates,
  performAutoScroll,
} from "./dashboardHelpers";
import { filterCoinsByAssetType, getOriginalCoinAssetType } from "../../services/currency-helpers";
import { setAssetType } from "../../state/action-creators";
import { useLocation } from "react-router-dom";
import { CoinsTable } from "../../components/CoinsTable";

let today = new Date();
today.setHours(0, 0, 0, 0);
let oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));


export const GeneralDashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const scrollRef = useRef<null | HTMLDivElement>(null)

  const coins = useSelector((state: RootState) => state.coins.allCoins);

  const startDate = useSelector(
    (state: RootState) => state.dashboard.startDate
  );
  const endDate = useSelector((state: RootState) => state.dashboard.endDate);
  const currentAssets = useSelector(
    (state: RootState) => state.dashboard.currentAssets
  );
  const filteredAssets = useSelector(
    (state: RootState) => state.dashboard.filteredAssets
  );

  const urlLocation = useLocation();
  const urlParams = new URLSearchParams(urlLocation.search);
  const assetTypeParamValue = urlParams.get('coin-type');
  const assetType = useSelector(
    (state: RootState) => state.dashboard.assetType
  );
  useEffect(() => {
    if (assetTypeParamValue) {
      if (assetTypeParamValue in assetTypesMapping) {
        dispatch(setAssetType(assetTypeParamValue as TAssetType))
      }
    }
  }, []);

  const graphDataType = useSelector(
    (state: RootState) => state.dashboard.graphDataType
  );

  const { setCurrentAssets, setFilteredAssets } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [coinTableFinishedLoading, setCoinTableFinishedLoading] = useState(false);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  // Dashboard-iternal state
  const [graphIsLoading, setGraphIsLoading] = useState(true);
  const [tableData, setTableData] = useState<CoinTableData[] | undefined>();
  const [unmeasuredTableData, setUnmeasuredTableData] = useState<CoinTableData[] | undefined>();
  const [currentTableData, setCurrentTableData] = useState<
    CoinTableData[] | undefined
  >();
  const [colorPalette, setColorPalette] = useState<Map<string, string> | undefined>(undefined);

  // All GraphDataPoints
  const [allConsumptionGraphData, setAllConsumptionGraphData] = useState<
    CoinConsumptionData[] | undefined
  >();
  const [allEmissionGraphData, setAllEmissionGraphData] = useState<
    CoinEmissionData[] | undefined
  >();
  // GraphDataPoints filtered with start and end
  const [periodConsumptionGraphData, setPeriodConsumptionGraphData] = useState<
    CoinConsumptionData[] | undefined
  >();
  const [periodEmissionGraphData, setPeriodEmissionGraphData] = useState<
    CoinEmissionData[] | undefined
  >();
  // GraphDataPoints that are currently shown in graph
  const [filteredConsumptionGraphData, setFilteredConsumptionGraphData] =
    useState<CoinGraphData | undefined>();
  const [filteredEmissionGraphData, setFilteredEmissionGraphData] = useState<
    CoinGraphData | undefined
  >();

  const [graphLabels, setGraphLabels] = useState<string[]>(
    getDatesBetween(startDate, endDate)
  );

  const [width, getWidth] = useState(window.innerWidth);

  const setWidth = () => {
    getWidth(window.innerWidth);
  };

  const checkCoinsLoaded = (): boolean => {
    return (coins && coins.length > 0)
  }

  const onCoinTableFinishedLoading = (): void => {
    setCoinTableFinishedLoading(true)
  }


  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);

  // const tableHead = [
  //   t("general-dashboard.table.currency"),
  //   t("general-dashboard.table.marketcap"),
  //   t("general-dashboard.table.load"),
  //   t("general-dashboard.table.electricity"),
  //   t("general-dashboard.table.emission"),
  // ];

  // Create color palette on currency load
  useEffect(() => {
    if (checkCoinsLoaded()) {
      const assetCoins = filterCoinsByAssetType(assetType as TAssetType, coins);
      const assetCoinsNum = assetCoins.length;
      const colorsBlue = chroma.scale(blueColors).colors(assetCoinsNum);
      const colorsSpectral = chroma.cubehelix().gamma(0.8).lightness([0.3, 0.7]).scale().correctLightness().colors(assetCoinsNum);

      const assetTickersSorted = assetCoins.map((currency) => (currency.ticker)).sort();
      const colorPalette = new Map();
      assetTickersSorted.forEach((ticker, index) => {
        const currentBlueColor = colorsBlue[index];
        const currentColorfulColor = colorsSpectral[index];
        colorPalette.set(ticker, [currentBlueColor, currentColorfulColor]);
      })
      setColorPalette(colorPalette)
    }

  }, [coins, assetType]);

  // Fetch table data initially on page load
  useEffect(() => {
    const fetchTableData = async () => {
      const tableData = await getAllTableData();
      const unmeasuredTableData = await getUnmeasuredTableData();
      setTableData(tableData);
      setUnmeasuredTableData(unmeasuredTableData)
    };
    fetchTableData();
  }, []);

  // Fetch all graphdata for consumption and emission on page load
  useEffect(() => {
    const fetchGraphData = async () => {
      if (checkCoinsLoaded()) {
        const emissionData = await getAllEmissions(coins);
        setAllEmissionGraphData(emissionData);
        const consumptionData = await getAllEnergyConsumptions();
        setAllConsumptionGraphData(consumptionData);

        // set timeframe for shown data initially
        const periodConsumption = fitConsumptionGraphDataToDates(
          consumptionData,
          today.toISOString(),
          oneYearAgo.toISOString()
        );
        const periodEmission = fitEmissionGraphDataToDates(
          emissionData,
          today.toISOString(),
          oneYearAgo.toISOString()
        );

        setPeriodConsumptionGraphData(periodConsumption);
        setPeriodEmissionGraphData(periodEmission);
        setGraphIsLoading(false);
      };
    }
    fetchGraphData();
  }, [coins]);


  useEffect(() => {
    setCurrentAssets(
      filterCoinsByAssetType(assetType as TAssetType, coins)
    );
    setFilteredAssets(
      filterCoinsByAssetType(assetType as TAssetType, coins)
    );

  }, [assetType, coins]);

  useEffect(() => {

    if (checkCoinsLoaded()) {
      setCurrentTableData(
        tableData?.filter(
          (entry) =>
            getOriginalCoinAssetType(coins.find((currency) => currency.ticker === entry.ticker)!) === assetType
        )
      );
    }
  }, [assetType, coins, tableData]);

  // Generate new datapoints for graph if startdate, enddate or currencies changed
  useEffect(() => {
    if (graphIsLoading === false) {
      setGraphLabels(getDatesBetween(startDate, endDate));

      const adjustedConsumptionData = fitConsumptionGraphDataToDates(
        allConsumptionGraphData,
        startDate.toISOString(),
        endDate.toISOString()
      );

      const adjustedEmissionData = fitEmissionGraphDataToDates(
        allEmissionGraphData,
        startDate.toISOString(),
        endDate.toISOString()
      );
      setPeriodConsumptionGraphData(adjustedConsumptionData);
      setPeriodEmissionGraphData(adjustedEmissionData);
    }
  }, [
    currentAssets,
    startDate,
    endDate,
    graphIsLoading,
    allConsumptionGraphData,
    allEmissionGraphData,
  ]);

  // scroll to graph
  useEffect(() => {
    performAutoScroll(width, scrollRef)
  }, []);

  // reload graph and table with fitting data if current assets or datapoints changed
  useMemo(() => {
    let zeros = new Array(graphLabels.length);
    for (let i = 0; i < graphLabels.length; ++i) zeros[i] = null;
    let consumptionDatasets = filteredAssets.map((asset) => {
      return {
        label: asset.name || "none",
        data:
          periodConsumptionGraphData?.find(
            (entry) => entry.ticker === asset.ticker
          )?.yearlyConsumption || zeros,
        // backgroundColor: chartColors.get(asset.ticker) || defaultColor,
        backgroundColor: colorPalette!.get(asset.ticker)![1] || defaultColor,
        borderColor: colorPalette!.get(asset.ticker)![1] || defaultColor,
        pointRadius: 0,
        borderWidth: width < 600 ? 1.5 : 2,
      };
    });

    let emissionDatasets = filteredAssets.map((asset) => {
      return {
        label: asset.name || "none",
        data:
          periodEmissionGraphData?.find(
            (entry) => entry.ticker === asset.ticker
          )?.yearlyEmissions || zeros,
        backgroundColor: colorPalette!.get(asset.ticker)![1] || defaultColor,
        borderColor: colorPalette!.get(asset.ticker)![1] || defaultColor,
        pointRadius: 0,
      };
    });

    setFilteredConsumptionGraphData({
      labels: graphLabels,
      datasets: consumptionDatasets,
    });
    setFilteredEmissionGraphData({
      labels: graphLabels,
      datasets: emissionDatasets,
    });
  }, [
    graphLabels,
    filteredAssets,
    periodConsumptionGraphData,
    width,
    periodEmissionGraphData,
  ]);

  const toggleFilterMenu = () => {
    setMobileFiltersOpen(!mobileFiltersOpen);
  };


  return (
    <div>
      <DashboardControls
        popup={true}
        popupOpen={mobileFiltersOpen}
        closeMobile={toggleFilterMenu}
      ></DashboardControls>
      <div
        className={`min-h-screen ${mobileFiltersOpen ? "hidden" : "relative"}`}
      >
        {/* Header */}
        <div className="-mt-3 md:hidden fixed z-[60] left-2/4 -translate-x-2/4">
          <FilterButton text="Filter" onClick={toggleFilterMenu}></FilterButton>
        </div>
        <div className="hidden md:block">
          <HeaderSection
            titles={["general-dashboard.title"]}
            subtitle={t("general-dashboard.heading")}
          ></HeaderSection>
        </div>
        <div className="block md:hidden">
          <HeaderSection
            titles={[
              "general-dashboard.mobile-title1",
              "general-dashboard.mobile-title2",
            ]}
            subtitle={t("general-dashboard.heading")}
          ></HeaderSection>
        </div>
        {/* wrapper for setting safe-area padding on mobile */}
        <div
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
          }}
        >
          <div className="pt-8 xsm:pt-16 lg:px-20 max-w-screen-2xl ml-auto mr-auto" ref={scrollRef}>
            <CoinsTable
              inputData={tableData}
              unmeasuredCoinsInputData={unmeasuredTableData}
              onCoinTableFinishedLoading = {onCoinTableFinishedLoading}
            />
            {coinTableFinishedLoading && <div className="mt-24">
              <div className="text-center">
                <Heading
                  text={t(`general-dashboard.table-headline-${assetType}`)}
                  size="h2"
                />
              </div>
              <div className="my-12">
                <DashboardControls
                  popupOpen={false}
                  popup={false}
                ></DashboardControls>
              </div>
              <div className="px-4 sm:px-6 lg:px-0 md:mt-4 md:mb-12 my-0">
                <div>
                  {filteredConsumptionGraphData && filteredEmissionGraphData ? (
                    <>
                      <CurrencyGraph
                        currentAssetType={assetType}
                        graphDataType={graphDataType}
                        data={
                          graphDataType === "consumption"
                            ? filteredConsumptionGraphData
                            : filteredEmissionGraphData
                        }
                      ></CurrencyGraph>
                      <div className="h-7 pt-4">
                        <LoadingSpinner loading={graphIsLoading}></LoadingSpinner>
                      </div>
                    </>
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>
              <div className="px-5 sm:px-6 lg:px-8 mt-8 md:mt-16 mb-64">
                <div className="mb-5">
                  <Paragraph
                    texts={chartTableTextFragments}
                    linktexts={chartTableTextLinkTexts}
                    links={chartTableTextLinks}
                  ></Paragraph>
                </div>
                <div className="flex justify-center mt-16">
                  <Button
                    text={t("general-dashboard.cta-button")}
                    onClick={() =>
                      window.open("mailto:hi@carbon-ratings.com", "_blank")
                    }
                    type="cta"
                  ></Button>
                </div>
              </div>

            </div>}

            <div className="fixed bottom-0 right-0  mr-5 mt-5 mb-5 md:hidden">
              <FilterButton onClick={toggleFilterMenu}></FilterButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDashboard;
