import React from "react";

export interface HeadingProps {
  text: string;
  size: "h1" | "h2" | "h3" | "h4" | "h5";
  color?: "primary" | "secondary";
  classes?: string;
  light?: boolean;
}

export const Heading: React.FC<HeadingProps> = (props) => {
  const colorClasses: string = `${
    props.light
      ? "text-white"
      : props.color && props.color === "secondary"
      ? "text-secondaryblue "
      : "text-primaryblue "
  }`;
  const h1Classes: string =
    "lg:text-5xl md:text-4xl text-3xl font-roboto font-regular";
  const h2Classes: string =
    "lg:text-3xl md:text-2xl text-2xl font-roboto md:font-light font-regular";
  const h3Classes: string =
    "lg:text-xl md:text-lg text-lg font-roboto mb-9 lg:text-left text-center";
  const h4Classes: string =
    "lg:text-2xl md:text-2xl text-base font-roboto font-light";

  const h1: JSX.Element = (
    <h1
      className={`${colorClasses} ${h1Classes} ${
        props.classes ? props.classes : ""
      }`}
    >
      {props.text}
    </h1>
  );
  const h2: JSX.Element = (
    <h2
      className={`${colorClasses} ${h2Classes} ${
        props.classes ? props.classes : ""
      }`}
    >
      {props.text}
    </h2>
  );
  const h3: JSX.Element = (
    <h3
      className={`${colorClasses} ${h3Classes} ${
        props.classes ? props.classes : ""
      }`}
    >
      {props.text}
    </h3>
  );
  const h4: JSX.Element = (
    <h4
      className={`${colorClasses} ${h4Classes} ${
        props.classes ? props.classes : ""
      }`}
    >
      {props.text}
    </h4>
  );

  const h5: JSX.Element = <h5 className={`${colorClasses}`}>{props.text}</h5>;

  const headings = { h1: h1, h2: h2, h3: h3, h4: h4, h5: h5 };

  return headings[`${props.size}`];
};
