import React, { useState, useEffect, useCallback, RefObject } from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState, actionCreators } from "../../state";
import { bindActionCreators } from "redux";
import { SelectorTabs } from "../../components/SelectorTabs";
import { FilterButton } from "../../components/FilterButton";

import {
  assetTypesGraphMapping,
  graphdataTypesMapping,
  timeFrames,
  today,
} from "../../pages/GeneralDashboard/dashboardConstants";
import { CustomDatePicker } from "../../components/CustomDatePicker";
import { TimespanSelectorButton } from "../../components/TimespanSelectorButton";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { CheckBoxGroup, ISelectedItem } from "../../components/CheckBoxGroup";

import { Coin, TAssetType, TGraphdataType } from "../../typings/CurrencyTypes";

export interface DashboardControlsProps {
  isEthereumMergePage?: boolean;
  popupOpen: boolean;
  popup: boolean;
  closeMobile?: () => void;
}

export const DashboardControls: React.FC<DashboardControlsProps> = ({
  isEthereumMergePage,
  popupOpen,
  popup,
  closeMobile,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const coins = useSelector((state: RootState) => state.coins.allCoins);

  const startDate = useSelector(
    (state: RootState) => state.dashboard.startDate
  );
  const endDate = useSelector((state: RootState) => state.dashboard.endDate);
  const currentAssets = useSelector(
    (state: RootState) => state.dashboard.currentAssets
  );

  const assetType = useSelector(
    (state: RootState) => state.dashboard.assetType
  );
  const graphDataType = useSelector(
    (state: RootState) => state.dashboard.graphDataType
  );

  const {
    setFilteredAssets,
    setStartDate,
    setEndDate,
    setAssetType,
    setGraphDataType,
  } = bindActionCreators(actionCreators, dispatch);

  const [coinsLoading, setCoinsLoading] = useState(true);
  const [fixedTimeSpan, setFixedTimeSpan] = useState(0);


  // track if currencies and tokens are initially loading
  useEffect(() => {
    if (coins.length >= 1) {
      setCoinsLoading(false);
    }
  }, [coins]);

  useEffect(() => {
    setFilteredAssets(currentAssets);
  }, [currentAssets]);


  const onClickFixedTimeFrame = useCallback(
    (value: number) => {
      const start = new Date(
        new Date().setMonth(new Date().getMonth() - value)
      );
      setStartDate(start);
      setEndDate(today);
      setFixedTimeSpan(value);
    },
    [setEndDate, setStartDate]
  );

  const onFilterChange = useCallback(
    (values: ISelectedItem[]) => {

      setFilteredAssets(
        currentAssets.filter((asset) => values.some((value) => value.ticker === asset.ticker))
      );
    },
    [currentAssets] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const createCheckBoxElements = (coins: Coin[]) => {
    const elements = coins.map((coins) => ({
      label: coins.name,
      initialChecked: true,
      ticker: coins.ticker,
    }));
    return elements;
  };

  const popupClasses = "fixed overflow-y-scroll  bg-primaryblue inset-0 mt-5";
  const mainSiteMobileHide = `${popup ? "" : "hidden"}`;
  const popUpHide = `${popup ? "hidden" : ""}`;

  return (
    <div
      id="dashboard-controls"
      className={`${popup ? popupClasses : ""} ${popup && !popupOpen ? "hidden" : ""
        } text-xs sm:text-base`}
    >
      <div className="grid grid-cols-9 md:my-9 px-5 xl:px-0">
        <div
          className={`xl:col-span-6 md:col-start-2 md:col-span-7 col-span-9 xl:pr-28 md:mb-0 mb-10 xsm:min-w-[400px] ${popUpHide}`}
        >
          {!isEthereumMergePage &&
            <div>
              <SelectorTabs
                options={Object.keys(assetTypesGraphMapping) as TAssetType[]}
              ></SelectorTabs>
            </div>}
          <div className="mt-4 xsm:mt-0.5">
            <SelectorTabs
              options={Object.keys(graphdataTypesMapping) as TGraphdataType[]}
            ></SelectorTabs>
          </div>

        </div>
        <div
          className={`md:flex justify-end md:col-span-7 col-span-9 md:col-start-2 xl:mt-0 md:mt-5 mt-24 xl:col-span-3 ${mainSiteMobileHide}`}
        >
          <div className={`flex flex-col gap-4 xsm:gap-0.5 items-stretch xl:w-96 w-full`}
          >
            <div className="flex flex-col xsm:flex-row items-center gap-0.5 grow-0 shrink-0">
              <div className="grow shrink-0">
                <CustomDatePicker
                  key={"startDateSelector"}
                  selected={startDate}
                  onSelect={(date) => {
                    setStartDate(date);
                  }}
                ></CustomDatePicker>
              </div>
              <div className="grow shrink-0">
                <CustomDatePicker
                  key={"endDateSelector"}
                  selected={endDate}
                  onSelect={(date) => {
                    setEndDate(date);
                  }}
                  minDate={startDate}
                ></CustomDatePicker>
              </div>
            </div>
            <div className="flex flex-col xsm:flex-row items-center gap-0.5 grow-0 shrink-0">
              {timeFrames.map((timeframe) => (
                <div
                  className="grow"
                  key={timeframe.label}
                >
                  <TimespanSelectorButton
                    text={timeframe.label}
                    month={timeframe.months}
                    isActive={fixedTimeSpan === timeframe.months}
                    isPopup={popup}
                    setFixedTimeFrame={onClickFixedTimeFrame}
                  ></TimespanSelectorButton>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Graphsettings section */}
      <div
        id="graphsettings-filter"
        className={`${mainSiteMobileHide} md:block md:pb-0 pb-24`}
      >
        {!isEthereumMergePage &&
          <div className="mt-20 md:px-5 xl:px-0">
            <div className="">
              {coinsLoading ? (
                <LoadingSpinner loading={true}></LoadingSpinner>
              ) : (
                <div>
                  <CheckBoxGroup
                    elements={createCheckBoxElements(currentAssets)}
                    currentAssetType={assetType}
                    onChange={onFilterChange}
                    light={popup}
                  ></CheckBoxGroup>
                </div>
              )}
            </div>
          </div>
        }
      </div>
      <div className="fixed bottom-0 right-0  mr-5 mt-5 mb-5">
        {popup ? (
          <FilterButton
            type={"close"}
            onClick={closeMobile || (() => { })}
          ></FilterButton>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
