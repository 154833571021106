import { Action } from "../actions";
import { ActionType } from "../action-types";
// import { setCurrencies } from "../action-creators";
// import { getCurrencyList } from "../../services/currency-service";
// import { Dispatch } from "redux";
import { Currency, TAssetType, TGraphdataType } from "../../typings/CurrencyTypes";

export interface DashboardState {
  currentAssets: Currency[];
  filteredAssets: Currency[];
  startDate: Date;
  endDate: Date;
  assetType: TAssetType;
  graphDataType: TGraphdataType;
}

let today = new Date();
today.setHours(0, 0, 0, 0);
let oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

const initialState: DashboardState = {
  currentAssets: [],
  filteredAssets: [],
  startDate: oneYearAgo,
  endDate: today,
  assetType: "pow",
  graphDataType: "consumption",
};

export const dashboardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_CURRENT_ASSETS: {
      return {
        ...state,
        currentAssets: action.payload,
      };
    }
    case ActionType.SET_FILTERED_ASSETS: {
      return {
        ...state,
        filteredAssets: action.payload,
      };
    }
    case ActionType.SET_STARTDATE: {
      return {
        ...state,
        startDate: action.payload,
      };
    }
    case ActionType.SET_ENDDATE: {
      return {
        ...state,
        endDate: action.payload,
      };
    }
    case ActionType.SET_ASSET_TYPE: {
      return {
        ...state,
        assetType: action.payload,
      };
    }
    case ActionType.SET_GRAPHDATA_TYPE: {
      return {
        ...state,
        graphDataType: action.payload,
      };
    }
    default:
      return state;
  }
};
