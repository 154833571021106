import { combineReducers } from "redux";
//import { loginReducer } from "./loginReducer";
import { currencyReducer } from "./currencyReducer";
import { dashboardReducer } from "./dashboardReducers";

const reducers = combineReducers({
  coins: currencyReducer,
  dashboard: dashboardReducer,
});

export default reducers;
