// import btc from "../assets/currencylogos/btc.png";
// import eth from "../assets/currencylogos/eth.png";
// import ltc from "../assets/currencylogos/ltc.png";
// import bch from "../assets/currencylogos/bch.png";
// import bsv from "../assets/currencylogos/bsv.png";
// import dash from "../assets/currencylogos/dash.png";
// import doge from "../assets/currencylogos/doge.png";
// import zec from "../assets/currencylogos/zec.png";
// import ada from "../assets/currencylogos/ada.png";
// import avax from "../assets/currencylogos/avax.png";
// import dot from "../assets/currencylogos/dot.png";
// import sol from "../assets/currencylogos/sol.png";
// import xtz from "../assets/currencylogos/xtz.png";
// import algo from "../assets/currencylogos/algo.png";
// import ksm from "../assets/currencylogos/ksm.png";
// import atom from "../assets/currencylogos/atom.png";
// import kava from "../assets/currencylogos/kava.png";
// import mina from "../assets/currencylogos/mina.png";
// import luna from "../assets/currencylogos/luna.png";
// import xrp from "../assets/currencylogos/xrp.png";
// import matic from "../assets/currencylogos/matic.png"
// import trx from "../assets/currencylogos/trx.png"
// import bnb from "../assets/currencylogos/bnb.png"
// import usdc from "../assets/currencylogos/usdc.png"
// import shib from "../assets/currencylogos/shib.png"
// import dai from "../assets/currencylogos/dai.png"
// import uni from "../assets/currencylogos/uni.png"
// import busd from "../assets/currencylogos/busd.png"
// import leo from "../assets/currencylogos/leo.png"
// import link from "../assets/currencylogos/link.png"

import { Coin, ICoinLogos } from "../typings/CurrencyTypes";


// export const currencyLogos = [
//   { ticker: "btc", logo: btc },
//   { ticker: "eth", logo: eth },
//   { ticker: "ltc", logo: ltc },
//   { ticker: "bch", logo: bch },
//   { ticker: "bsv", logo: bsv },
//   { ticker: "dash", logo: dash },
//   { ticker: "doge", logo: doge },
//   { ticker: "zec", logo: zec },
//   { ticker: "ada", logo: ada },
//   { ticker: "avax", logo: avax },
//   { ticker: "dot", logo: dot },
//   { ticker: "sol", logo: sol },
//   { ticker: "xtz", logo: xtz },
//   { ticker: "algo", logo: algo },
//   { ticker: "ksm", logo: ksm },
//   { ticker: "atom", logo: atom },
//   { ticker: "kava", logo: kava },
//   { ticker: "mina", logo: mina },
//   { ticker: "luna", logo: luna },
//   { ticker: "xrp", logo: xrp },
//   { ticker: "eth2", logo: eth },
//   { ticker: "matic", logo: matic },
//   { ticker: "trx", logo: trx },
//   { ticker: "bnb", logo: bnb },
//   { ticker: "usdc", logo: usdc },
//   { ticker: "shib", logo: shib },
//   { ticker: "dai", logo: dai },
//   { ticker: "uni", logo: uni },
//   { ticker: "busd", logo: busd },
//   { ticker: "leo", logo: leo },
//   { ticker: "link", logo: link },



// ];


// const currencyLogosList = [
//   "btc", "eth", "ltc", "bch", "bsv",
//   "dash", "doge", "zec", "ada", "avax",
//   "dot", "sol", "xtz", "algo", "ksm",
//   "atom", "kava", "mina", "luna", "xrp",
//   "eth2", "matic", "trx", "bnb", "usdc",
//   "shib", "dai", "uni", "busd", "leo",
//   "link"
// ];



export const getCoinLogos = (coins: Coin[]): ICoinLogos => {

  return coins.map((coin: Coin) => {
    let logoPath = ""
    try {
      logoPath = require(`../assets/currencylogos/${coin.ticker}.png`)
    }
    catch (error) {
      console.log(`Coin logo for ${coin.ticker} not found`)
    }
    return {
      ticker: coin.ticker,
      logo: logoPath
    }
  })
}
