const defaultTheme = require('tailwindcss/defaultTheme')
// import defaultTheme from './node_modules/tailwindcss/defaultTheme';


module.exports = {
  content: ["./src/**/*.{html,js,jsx,ts,tsx}"],
  theme: {
    fontFamily: {
      roboto: ["Roboto", "sans-serif"],
      mono: ["Roboto Mono", "monospace"],
    },
    extend: {
      colors: {
        //primaryblue: "#003C52",
        primaryblue: "#283044",
        secondaryblue: "#3B93BA",
        ternaryblue: "#2E77AA",
        lightgrey: "#F2F2F2",
        mediumgrey: "#B3C6CC",
        white: "#ffffff",
        buttonUnhovered: "#f8f8f8",
        primaryblueDisabled: "#28304499",
        primaryblueHover: "#283044bb",

      },
      backgroundImage: {
        /* eslint-disable */
        'checked': "url('../src/assets/icons/checkbox-checked.png')",
        'unchecked': "url('../src/assets/icons/checkbox-circle.png')",
        'checked-white': "url('../src/assets/icons/checkbox-checked-white.png')",
        'unchecked-white': "url('../src/assets/icons/checkbox-circle-white.png')",
        'hero-pattern-full': "url('../src/assets/header-full.png')",
        'hero-pattern-cut': "url('../src/assets/header-cut.png')",
      },
      screens: {
        'xsm': '415px',
        ...defaultTheme.screens,
      },
    },
  },
};
