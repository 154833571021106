import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { Coin, Currency, TAssetType, TGraphdataType, Token} from "../../typings/CurrencyTypes";


export const setCurrencies = (currencies: Currency[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CURRENCIES,
      payload: currencies,
    });
  };
};

export const setTokens = (tokens: Token[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_TOKENS,
      payload: tokens,
    });
  };
};

export const setCoins = (coins: Coin[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_COINS,
      payload: coins,
    });
  };
};

export const setUnmeasuredCoins = (coins: Coin[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_UNMEASURED_COIN,
      payload: coins,
    });
  };
};

export const setCurrentAssets = (currencies: Currency[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CURRENT_ASSETS,
      payload: currencies,
    });
  };
};

export const setFilteredAssets = (currencies: Currency[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_FILTERED_ASSETS,
      payload: currencies,
    });
  };
};

export const setStartDate = (date: Date) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_STARTDATE,
      payload: date,
    });
  };
};

export const setEndDate = (date: Date) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ENDDATE,
      payload: date,
    });
  };
};

export const setAssetType = (assetType: TAssetType) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ASSET_TYPE,
      payload: assetType,
    });
  };
};

export const setGraphDataType = (graphDataType: TGraphdataType) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_GRAPHDATA_TYPE,
      payload: graphDataType,
    });
  };
};
