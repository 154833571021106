import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../state";
import { CoinTableData, ProcessedCoinTableData } from "../../typings/CurrencyTypes";
import { Paragraph } from "../Paragraph";
import { LoadingSpinner } from "../LoadingSpinner";
import { capitalizeUnit, convertToBaseUnit, findCurrencyByTicker, getOriginalCoinAssetType } from "../../services/currency-helpers";
import { CointypeLabel } from "../CointypeLabel";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { HoverTooltip } from "../HoverTooltip";



export interface ICoinsTableProps {
  inputData: CoinTableData[] | undefined;
  unmeasuredCoinsInputData: CoinTableData[] | undefined;
  onCoinTableFinishedLoading: () => void;
}

type TTableProps = keyof ProcessedCoinTableData;

interface ISortingProperties {
  sortingProp: TTableProps;
  order: "asc" | "desc"
}


export const CoinsTable: React.FC<ICoinsTableProps> = (props) => {

  const { t } = useTranslation();
  const coins = useSelector((state: RootState) => state.coins.allCoins);
  const coinLogos = useSelector((state: RootState) => state.coins.allCoinsLogos)


  const [coinTableLoading, setCoinTableLoading] = useState(true)
  const [tableData, setTableData] = useState<ProcessedCoinTableData[]>([])
  const [sortingProps, setSortingProps] = useState<ISortingProperties>({ sortingProp: "marketcap", order: "desc" })
  const [tableConsensusFootnotes, setTableConsensusFootnotes] = useState<{ [footnoteNumber: number]: string }[]>([]);


  const tableHead: { name: TTableProps, label: string }[] = [
    { name: "name", label: t("general-dashboard.coins-table.currency") },
    { name: "type", label: t("general-dashboard.coins-table.type") },
    { name: "marketcap", label: t("general-dashboard.coins-table.marketcap") },
    { name: "power", label: t("general-dashboard.coins-table.load") },
    { name: "consumption", label: t("general-dashboard.coins-table.electricity") },
    { name: "emission", label: t("general-dashboard.coins-table.emission") },
  ];


  const processTableData = (inputData: CoinTableData[], unmeasuredCoinsInputData: CoinTableData[]) => {

    const customConsensusTypes: { [ticker: string]: string } = {
      xrp: "XRP Ledger Consensus Protocol (Proof of Association)",
      xlm: "Stellar Consensus Protocol",
      hbar: "Hashgraph Consensus Algorithm",
      vet: "Proof of Authority",
    }

    const processedData: ProcessedCoinTableData[] = [];
    const data = [...inputData, ...unmeasuredCoinsInputData]

    data.forEach(elem => {
      if (elem.ticker !== "eth") {
        const coin = findCurrencyByTicker(coins, elem.ticker)
        const newTicker = elem.ticker === "eth2" ? "eth" : elem.ticker
        const power_unit = capitalizeUnit(coin.outputs.power);
        const consumption_unit = capitalizeUnit(coin.outputs.electricity);
        const emission_unit = capitalizeUnit(coin.outputs.emissions);
        const custom_consensus_type: string | undefined = customConsensusTypes[coin.ticker] ?? undefined;
        const elemWithUnits: ProcessedCoinTableData = {
          ...elem,
          name: coin.name,
          type: getOriginalCoinAssetType(coin)!,
          original_ticker: coin.ticker,
          ticker: newTicker,
          power_unit,
          consumption_unit,
          emission_unit,
          power_base_unit: convertToBaseUnit(elem.power, power_unit),
          consumption_base_unit: convertToBaseUnit(elem.consumption, consumption_unit),
          emission_base_unit: convertToBaseUnit(elem.emission, emission_unit),
          custom_consensus_type: custom_consensus_type,
          custom_consensus_type_footnote_number: undefined //to be assigned after initial sort for market cap
        }
        processedData.push(elemWithUnits)
      }
    })

    const processedDataSorted = sortCoinTableData(processedData, { sortingProp: "marketcap", order: "desc" });

    //assign footnote number for each coin with custom consensus type: 
    let footnote_counter = 0
    const footnotesByNumber: { [footnoteNumber: number]: string }[] = []
    processedDataSorted.forEach(elem => {
      const ticker = elem.ticker
      if (ticker in customConsensusTypes) {
        footnote_counter++;
        elem.custom_consensus_type_footnote_number = footnote_counter
        footnotesByNumber.push({ [footnote_counter]: customConsensusTypes[ticker] })
      }
    })
    setTableConsensusFootnotes(footnotesByNumber)

    setTableData(processedDataSorted)
    setCoinTableLoading(false)
    props.onCoinTableFinishedLoading()
  }


  const sortCoinTableData = (unsortedData: ProcessedCoinTableData[], sortingProperty: ISortingProperties) => {

    let adjustedSortingProperty = sortingProperty.sortingProp
    const sortingOrder = sortingProperty.order

    if (["power", "consumption", "emission"].includes(adjustedSortingProperty)) {
      adjustedSortingProperty = `${adjustedSortingProperty}_base_unit` as TTableProps
    }

    const sortedData = unsortedData.sort((a, b) => {

      const valueA = a[adjustedSortingProperty];
      const valueB = b[adjustedSortingProperty];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (sortingOrder === "asc") {
          return valueB.localeCompare(valueA, undefined, { sensitivity: "base" }); // Sort strings z-a order
        } else if (sortingOrder === "desc") {
          return valueA.localeCompare(valueB, undefined, { sensitivity: "base" }); // Sort strings a-z order
        }
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        if (isNaN(valueA)) {
          return 1; // Place NaN values at the end
        } else if (isNaN(valueB)) {
          return -1; // Place NaN values at the end
        } else {
          if (sortingOrder === "asc") {
            return valueA - valueB; // Sort numbers in ascending order
          } else if (sortingOrder === "desc") {
            return valueB - valueA; // Sort numbers in descending order
          }
        }
      }

      // Handle other types or invalid cases as needed
      return 0;
    });

    return sortedData

  }

  const onSortButtonClick = (newSortingProperty: TTableProps) => {

    const oldSortingProps = sortingProps
    let currentSortingOrder = sortingProps.order
    if (oldSortingProps.sortingProp === newSortingProperty) {
      currentSortingOrder === 'desc' ? currentSortingOrder = 'asc' : currentSortingOrder = 'desc'
    } else {
      currentSortingOrder = "desc"
    }

    const newSortingProps: ISortingProperties = { order: currentSortingOrder, sortingProp: newSortingProperty }
    sortAndUpdateTableData(newSortingProps)

  }


  const sortAndUpdateTableData = (sortingProperty: ISortingProperties) => {
    setSortingProps(sortingProperty)
    setTableData(sortCoinTableData(tableData, sortingProperty))
  }

  const formatNumber = (number: number, unit: string | null, unitIsPrefix: boolean = false, roundToOneFractionDigit: boolean = true) => {
    if (number < 0) {
      return "N/A";
    } else {
      return `${unitIsPrefix && unit ? `${unit} ` : ""}${number.toLocaleString("en-US", roundToOneFractionDigit ? { minimumFractionDigits: 1, maximumFractionDigits: 1 } : undefined)}${!unitIsPrefix && unit ? ` ${unit}` : ""}`
    }

  }

  const formatTableMarketCap = (entry: ProcessedCoinTableData) => {
    return formatNumber(entry.marketcap, "$", true, false)
  }

  const formatTableElectricalPower = (entry: ProcessedCoinTableData) => {
    return formatNumber(entry.power, entry.power_unit)
  }

  const formatTableElectricityConsumption = (entry: ProcessedCoinTableData) => {
    return formatNumber(entry.consumption, entry.consumption_unit)
  }

  const formatTableEmission = (entry: ProcessedCoinTableData) => {

    const changeUnitCoins: {
      [ticker: string]: {
        unit: string;
        factor: number;
      };
    } = {
      eth: {
        unit: "kg",
        factor: 1000
      },
      matic: {
        unit: "kg",
        factor: 1000
      }
    }

    if (entry.ticker in changeUnitCoins) {
      const coinChangeInfo = changeUnitCoins[entry.ticker]
      return formatNumber(entry.emission * coinChangeInfo.factor, coinChangeInfo.unit)
    } else {
      return formatNumber(entry.emission, entry.emission_unit)
    }
  }

  const renderUnavailableValueIcon = () => {
    return (
      <HoverTooltip
        text="Missing CCRI analysis"
      />)
  }

  const renderNumericTableCellValue = (value: number | null, entry: ProcessedCoinTableData, numberFormattingFunction: (entry: ProcessedCoinTableData) => string) => {
    if (value) {
      return numberFormattingFunction(entry);
    } else {
      return renderUnavailableValueIcon()
    }

  }


  useEffect(() => {
    if (coins && coinLogos && props.inputData && props.unmeasuredCoinsInputData && coins.length > 0 && coinLogos.length > 0) {
      processTableData(props.inputData, props.unmeasuredCoinsInputData)
    }
  }, [coins, coinLogos, props.inputData, props.unmeasuredCoinsInputData]);


  const renderMobileTable = (data: ProcessedCoinTableData[]) => {

    const mobileTableCellContainer = "mt-7 text-center"
    const mobileTableHeadClasses =
      "text-lg text-center font-semibold font-roboto text-primaryblue";
    const mobileTableDataClasses = "text-center text-primaryblue font-mono";

    return (data.map((entry) => {
      const coin = findCurrencyByTicker(coins, entry.original_ticker)
      return (
        <div
          className="odd:bg-lightgrey flex flex-col justify-center items-center py-12"
          key={entry.ticker}
        >
          <div className="flex flex-col items-center">
            <div className="">
              <img
                className="h-10 w-10 rounded-full mb-2"
                src={
                  coinLogos.find(
                    (element) => element.ticker === entry.ticker
                  )?.logo
                }
                alt=""
              />
            </div>
            <div className="flex flex-row justify-center items-center">
              <div className="font-medium text-primaryblue mr-3.5 text-lg font-roboto">
                {coin.name}
              </div>
              <div className="text-mediumgrey uppercase">
                {entry.ticker}
              </div>
            </div>
            <div className="mt-2">
              <CointypeLabel
                ticker={coin.ticker}
                equalSize
                footnoteNumber={entry.custom_consensus_type_footnote_number}
              />
            </div>
          </div>
          <div className={mobileTableCellContainer}>
            <span className={mobileTableHeadClasses}>{tableHead[2].label}</span>
            <br></br>
            <span
              className={mobileTableDataClasses}
            >{renderNumericTableCellValue(entry.marketcap, entry, formatTableMarketCap)}</span>
          </div>
          <div className={mobileTableCellContainer}>
            <span className={mobileTableHeadClasses}>{tableHead[3].label}</span>
            <br></br>
            <span className={mobileTableDataClasses}>{
              renderNumericTableCellValue(entry.power, entry, formatTableElectricalPower)
            }</span>
          </div>
          <div className={mobileTableCellContainer}>
            <span className={mobileTableHeadClasses}>{tableHead[4].label}</span>
            <br></br>
            <span
              className={mobileTableDataClasses}
            >{
                renderNumericTableCellValue(entry.consumption, entry, formatTableElectricityConsumption)
              }</span>
          </div>
          <div className={mobileTableCellContainer}>
            <span className={mobileTableHeadClasses}>{tableHead[5].label}</span>
            <br></br>
            <span className={mobileTableDataClasses}>{
              renderNumericTableCellValue(entry.emission, entry, formatTableEmission)
            }</span>
          </div>
        </div>
      )
    })
    )
  }

  const renderDesktopTable = (data: ProcessedCoinTableData[]) => {

    const renderSortingIcon = (clickedColName: string) => {
      if (sortingProps.order === "asc" && sortingProps.sortingProp === clickedColName) {
        return <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      } else {
        return <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
      }
    }
    return (
      <table className="hidden lg:table w-full">
        <thead className="">
          <tr>
            {tableHead.map((entry, index) => (
              <th
                scope="col"
                className="py-3.5 first:text-left text-center text-sm font-semibold font-roboto text-primaryblue sm:px-6"
                key={entry.name}
              >
                <div
                  className={`group inline-flex items-center w-full cursor-pointer ${index === 0 ? "justify-left" : "justify-center"}`}
                  onClick={() => onSortButtonClick(entry.name)}
                >
                  {entry.label}
                  <span className={`ml-2 h-5 flex-none flex items-center rounded text-gray-400 group-hover:bg-gray-100 group-focus:bg-gray-100 transition-all duration-300 ${entry.name === sortingProps.sortingProp ? "bg-gray-100 visible" : ""}`}>
                    {renderSortingIcon(entry.name)}
                  </span>
                </div>

              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white text-primaryblue font-mono">
          {data.map((entry) => {
            const coin = findCurrencyByTicker(coins, entry.original_ticker)
            return (
              <tr key={entry.ticker} className="odd:bg-lightgrey text-center">
                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm col-span-1">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          coinLogos.find(
                            (element) => (element.ticker === entry.ticker)
                          )?.logo
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-4 flex flex-row">
                      <div className="font-medium text-primaryblue mr-3.5">
                        {coin.name}
                      </div>
                      <div className="text-mediumgrey uppercase">
                        {entry.ticker}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="w-1 whitespace-pre py-4 px-3">
                  <CointypeLabel
                    ticker={coin.ticker}
                    equalSize
                    footnoteNumber={entry.custom_consensus_type_footnote_number}
                  />
                </td>
                {[
                  renderNumericTableCellValue(entry.marketcap, entry, formatTableMarketCap),
                  renderNumericTableCellValue(entry.power, entry, formatTableElectricalPower),
                  renderNumericTableCellValue(entry.consumption, entry, formatTableElectricityConsumption),
                  renderNumericTableCellValue(entry.emission, entry, formatTableEmission),
                ].map((value, i) => (
                  <td
                    className="whitespace-pre py-4 px-3 text-sm col-span-1"
                    key={`${entry.ticker}-${i}`}
                  >
                    <div>{value}</div>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>)
  }

  const renderFootnoteList = () => {
    return (
      <div className="flex flex-col gap-y-0.5">
        {
          tableConsensusFootnotes.map((footnoteElem) => {
            const [footnoteKey, footnoteValue] = Object.entries(footnoteElem)[0];
            // const footnoteText = `${footnoteKey}: ${footnoteValue}`
            return (
              <div
                className="flex flex-row w-full justify-end"
                key={footnoteKey}
              >
                <div 
                className="relative -top-[4px]"
                >
                  <Paragraph
                    text={footnoteKey}
                    grayText={true}
                    imgCaption={true}
                    containerJustify="end"
                    customSize="!text-[12px]"
                  />
                </div>
                <Paragraph
                  text={`: ${footnoteValue}`}
                  grayText={true}
                  imgCaption={true}
                  containerJustify="end"
                />
              </div>
            )
          })
        }
      </div>

    )
  }

  const renderTable = (data: ProcessedCoinTableData[]) => {

    return (
      <div>
        <div className="block lg:hidden w-full">
          {renderMobileTable(data)}
        </div>
        {renderDesktopTable(data)}
        <div className="w-full flex flex-col justify-end gap-y-2">
          <Paragraph
            texts={["Marketcaps are taken from "]}
            links={["https://www.coingecko.com/"]}
            linktexts={["CoinGecko.com"]}
            grayText={true}
            imgCaption={true}
            customStyle={"pt-2"}
            containerJustify="end"
          />
          {renderFootnoteList()}
        </div>
      </div>)
  }

  const renderComponent = () => {
    return (
      <div className="mb-8 overflow-hidden">
        {coinTableLoading ?
          <LoadingSpinner loading={true} />
          :
          renderTable(tableData)
        }
      </div>)
  }


  return renderComponent()

};