import React, { useState } from "react";
import { TAssetType, TGraphdataType } from "../../typings/CurrencyTypes";
import { assetTypesMapping, graphdataTypesMapping, } from "../../pages/GeneralDashboard/dashboardConstants";
import { RootState } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { setAssetType, setGraphDataType } from "../../state/action-creators";

export interface SelectorTabsProps {
  options: TAssetType[] | TGraphdataType[];
}

const commonClasses =
  "transition duration-500 ease-in-out hover:text-white flex items-center border-b-2 mt-0.5 xsm:mt-0 xsm:mr-0.5 text-center font-roboto relative w-full	sm:min-w-0 max-w-[200px] xsm:max-w-none flex-1 overflow-hidden py-2 px-2 cursor-pointer";
const selectedClasses =
  "text-primaryblue bg-buttonUnhovered hover:bg-primaryblue border-primaryblue cursor-pointer";
const unselectedClasses =
  "text-primaryblue bg-buttonUnhovered hover:bg-primaryblue border-buttonUnhovered";
// const disabledClasses = "hover:bg-primaryblueDisabled bg-lightgrey text-gray-400";

export const SelectorTabs: React.FC<SelectorTabsProps> = ({
  options,
}) => {

  const dispatch = useDispatch();

  const assetType = useSelector(
    (state: RootState) => state.dashboard.assetType
  );

  const graphDataType = useSelector(
    (state: RootState) => state.dashboard.graphDataType
  );

  const convertOptionToText = (option: TAssetType | TGraphdataType) => {
    if (option in assetTypesMapping) {
      return assetTypesMapping[option as TAssetType]
    }
    if (option in graphdataTypesMapping) {
      return graphdataTypesMapping[option as TGraphdataType]
    }
  }

  const onAssetOrGraphdataTypeChange = 
    (newType: TAssetType | TGraphdataType) => {
      if (newType in assetTypesMapping){
        dispatch(setAssetType(newType as TAssetType))
      }
      if (newType in graphdataTypesMapping){
        dispatch(setGraphDataType(newType as TGraphdataType))
      }
    }

  return (
    <div>
      <div className="">
        <div className="relative z-0 flex flex-col xsm:flex-row items-center" aria-label="Tabs">
          {options.map((option) => (
            <div
              key={option}
              className={`${commonClasses} ${option === assetType || option === graphDataType
                ? selectedClasses
                : unselectedClasses
                } 
           `}
              onClick={() => onAssetOrGraphdataTypeChange(option)}
            >
              <span className="mx-auto">{convertOptionToText(option)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
