import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/24/outline";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
  selected: Date;
  minDate?: Date;
  maxDate?: Date;
  onSelect?: (option: Date) => void;
}

const CustomStyledPicker = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>((props, ref) => (
  <div
    className="flex flex-row justify-center items-center text-center font-roboto text-primaryblue bg-buttonUnhovered py-2 md:px-4 px-2 cursor-pointer border-b-2 border-buttonUnhovered hover:text-white hover:bg-primaryblue hover:text-white transition duration-500 ease-in-out xsm:w-full w-[155px]"
    onClick={props.onClick}
    ref={ref}
  >
    <CalendarIcon className="h-5 w-5 mr-3" />
    <input ref={ref} {...props}></input>
  </div>
));

export const CustomDatePicker: React.FC<DatePickerProps> = ({
  selected,
  minDate,
  maxDate,
  onSelect,
}) => {
  const [startDate, setStartDate] = useState(selected);

  //const calledOnce = useRef(false);

  useEffect(() => {
    setStartDate(selected);
  }, [selected]);

  const onDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
      if (onSelect) {
        onSelect(date);
      }
    }
  };

  return (
    <div >
      <DatePicker
        className="font-roboto bg-transparent focus:outline-none w-28 text-center	-ml-2"
        selected={startDate}
        onChange={onDateChange}
        dateFormat="yyyy/MM/dd" // "dd/MM/yyyy"
        minDate={minDate || new Date("2007-01-01")} // ("01-01-2007")}
        maxDate={new Date()}
        customInput={<CustomStyledPicker />}
      />
    </div>
  );
};
