import React from "react";
import { Navlink, NavlinkProps } from "../Navlink";
import ccriLogo from "../../assets/CCRI-logo.png";

export interface FooterProps {
  links: Array<NavlinkProps>;
}

export const Footer: React.FC<FooterProps> = (props) => {
  return (
    <>
      <footer className="mt-24 bg-primaryblue sm:mt-12 md:px-0 px-5">
        <div className="grid grid-cols-3 items-center h-16 sm:px-6 lg:px-28">
          <div>
            <img
              className="block lg:hidden h-8 w-auto"
              src={ccriLogo}
              alt="CCRI"
            />
            <img
              className="hidden lg:block h-10 w-auto"
              src={ccriLogo}
              alt="CCRI"
            />
          </div>
          <div>
            <p className="text-center text-base text-white font-roboto ">
              &copy; 2023 CCRI
            </p>
          </div>
          <div>
            <nav
              className="-mx-5 -my-2 flex flex-wrap justify-end"
              aria-label="Footer"
            >
              {props.links.map((linkProps) => {
                return (
                  <div key={linkProps.text} className="px-5 py-2">
                    <Navlink {...linkProps}></Navlink>
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};
