export enum ActionType {
  LOGIN = "login",
  LOGOUT = "logout",
  SET_CURRENCIES = "set-currencies",
  SET_TOKENS = "set-tokens",
  SET_COINS = "set-coins",
  SET_UNMEASURED_COIN = "set-unmeasured-coin",
  SET_CURRENT_ASSETS = "set-current-assets",
  SET_FILTERED_ASSETS = "set-filtered-assets",
  SET_STARTDATE = "set-startdate",
  SET_ENDDATE = "set-enddate",
  SET_ASSET_TYPE = "set-asset-type",
  SET_GRAPHDATA_TYPE = "set-graphdata-type",
}
