import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Tick,
} from 'chart.js';
import { CCRI_CERTIFIED_INVESTOR_INDEX, CCRI_CERTIFIED_MINER_INDEX, chartHeigtInvestorPx as chartHeigtInvestorPxCss, chartHeigtMinerPx as chartHeigtMinerPxCss, GRAPH_X_AXIS_INVESTOR_INDEX, GRAPH_X_AXIS_MINER_INDEX, ISustainabilityData, TRankingType } from "./sustainabilityRankingConstants";
import { useTranslation } from "react-i18next";
import { getColumnNameByIndex, isMinerRanking } from "./sustainabilityRankingHelpers";
import * as tailwindConfig from "../../tailwind_config_symlink";
import LogoCryptooxygen from "../../assets/logo-cryptooxygen.png"



export interface ISustainabilityRankingGraphProps {
    data: ISustainabilityData[];
    rankingType: TRankingType;
}

export const SustainabilityRankingGraph: React.FC<ISustainabilityRankingGraphProps> = (props) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const { t } = useTranslation();



    const X_AXIS_INDEX = isMinerRanking(props.rankingType) ? GRAPH_X_AXIS_MINER_INDEX : GRAPH_X_AXIS_INVESTOR_INDEX;
    const Y_AXIS_INDEX = 0;
    const CCRI_CERTIFIED_INDEX = isMinerRanking(props.rankingType) ? CCRI_CERTIFIED_MINER_INDEX : CCRI_CERTIFIED_INVESTOR_INDEX;
    const X_AXIS_KEY = getColumnNameByIndex(props.data, X_AXIS_INDEX);
    const Y_AXIS_KEY = getColumnNameByIndex(props.data, Y_AXIS_INDEX);

    const datasets: ChartData<"bar", ISustainabilityData[]> = {
        datasets: [{
            data: props.data,
            parsing: {
                xAxisKey: X_AXIS_KEY,
                yAxisKey: Y_AXIS_KEY,
            }
        }],
    };

    const graphOptions = {
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2,
                backgroundColor: tailwindConfig.theme.extend.colors.primaryblue,
                hoverBackgroundColor: tailwindConfig.theme.extend.colors.secondaryblue
            },
        },
        scales: {
            x: {
                display: true,
                type: isMinerRanking(props.rankingType) ? "linear" as const : 'logarithmic' as const,
                beginAtZero: true,
                ticks: {
                    callback: function (value: string | number, index: number, values: Tick[]) {
                        if (!isMinerRanking(props.rankingType)) {
                            const ticks = [1, 10, 100, 1000, 10000]
                            if (ticks.includes(value as number)) {
                                return value;
                            } else {
                                return undefined
                            }
                        }
                        return value.toLocaleString("en-US");
                    },
                }
            },
            y: {
                display: true,
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: isMinerRanking(props.rankingType) ? t("sustainability-ranking.chart-title-miner") : t("sustainability-ranking.chart-title-investor"),
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let labels: string[] = []
                        Object.entries(context["raw"]).forEach(([key, value], index) => {
                            if (index > 0) {
                                let value_formatted = "";
                                if (index == CCRI_CERTIFIED_INDEX) {
                                    value_formatted = +(value as number) === 1 ? "yes" : "no"
                                } else {
                                    value_formatted = (+(value as number)).toLocaleString("en-us")

                                }
                                labels.push(`${key}: ${(value_formatted)}`)
                            }
                        })
                        return labels
                    }
                },
                cornerRadius: 7,
                displayColors: false,
                backgroundColor: "#f5f5f5e0",
                borderColor: "#555",
                borderWidth: 0.5,
                bodyColor: tailwindConfig.theme.extend.colors.primaryblue,
                titleColor: tailwindConfig.theme.extend.colors.primaryblue,
                titleFont: { weight: "bold", family: "Roboto", color: "black" },
                labelFont: { weight: "light", family: "Roboto", color: "black" },
                mode: "y" as const
            },
            legend: {
                display: false,
            }
        },
    }

    const getChartHeight = () => {
        return isMinerRanking(props.rankingType) ? chartHeigtMinerPxCss : chartHeigtInvestorPxCss;
    }

    const createGraphFooter = () => {
        const tableFooter =
            <div className="flex justify-center xl:justify-end gap-x-2 items-center text-gray-400 text-xs font-light mt-2 ">
                <p>
                    {t("sustainability-ranking.table-footer1")}
                </p>
                <img
                    className="h-4 object-scale-down"
                    src={LogoCryptooxygen}
                    alt="logo-cryptooxygen">
                </img>
            </div>
        return tableFooter
    }

    return (
        <>
            <div className={`w-full ${getChartHeight()}`}>
                <Bar options={graphOptions} data={datasets} />
            </div>
            {isMinerRanking(props.rankingType) &&
                <div>
                    {createGraphFooter()}
                </div>
            }
        </>

    );
};

export default SustainabilityRankingGraph



